import { setSortByOption } from "../../slices/searchRequestSlice";
import { FacetDrawerControls, FacetFilters } from "../filters/facetFilters/FacetFilters";
import { SortByMenu, SortOption } from "@magicad-cloud/component-library";
import { AttenuationFilterContainer } from "../filters/operativeFilters/AttenuationFilterContainer";
import { VentilationOperationPointFilterContainer } from "../filters/operativeFilters/VentilationOperationPointFilterContainer";
import {
  useAppDispatch,
  useAppSelector,
  useCurrentSearchOptionsResult,
  useCurrentSearchResults,
  useOperativeFilters,
} from "../../hooks";
import "./FilterControls.scss";

interface FilterControlsProps {
  facetDrawerControls: FacetDrawerControls;
}

export const FilterControls = ({ facetDrawerControls }: FilterControlsProps) => {
  const dispatch = useAppDispatch();

  const { attenuationEnabled, ventilationOperationPointEnabled } = useOperativeFilters();
  const { searchOptionsResultLoading } = useCurrentSearchOptionsResult();
  const { searchResultsLoading } = useCurrentSearchResults();

  const selectedSortOption = useAppSelector((state) => state.searchRequest.searchRequest.SortBy);

  const handleSortOptionChange = (option: SortOption) => {
    dispatch(setSortByOption(option));
  };

  return (
    <div className="FilterControls">
      <div className="FilterControls__left">
        <FacetFilters {...facetDrawerControls} />
        {attenuationEnabled ? <AttenuationFilterContainer /> : null}
        {ventilationOperationPointEnabled ? <VentilationOperationPointFilterContainer /> : null}
      </div>
      <div className="FilterControls__right">
        <SortByMenu
          selectedSortOption={selectedSortOption}
          disabled={searchOptionsResultLoading || searchResultsLoading}
          onOptionChange={handleSortOptionChange}
        />
      </div>
    </div>
  );
};
