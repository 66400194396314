import { useEffect } from "react";
import { config } from "../config/config";
import { setSearchOptionsRequestLanguageLocale } from "../slices/searchOptionsRequestSlice";
import { setSearchRequestLanguageLocale } from "../slices/searchRequestSlice";
import { SupportedLanguage } from "../common/types";
import { useAppDispatch } from "./reduxHooks";
import { useTranslation } from "react-i18next";

export const useLocale = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const newLocale = config.languageLocales[i18n.language as SupportedLanguage];

    dispatch(setSearchOptionsRequestLanguageLocale(newLocale));
    dispatch(setSearchRequestLanguageLocale(newLocale));
  }, [dispatch, i18n.language]);
};
