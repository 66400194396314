import { useEffect, useState } from "react";
import {
  Collection,
  CollectionCard,
  CollectionCardMenu,
  CollectionDeleteDialog,
  CollectionFilterMenuOption,
  CollectionFormDialog,
  CollectionFormValues,
  CollectionManagementDialog,
  CollectionManagementDialogContent,
  Product,
} from "@magicad-cloud/component-library";
import { useAppSelector } from "../../hooks";
import {
  useAddProductsToCollectionMutation,
  useCreateManufacturerCollectionMutation,
  useCreateUserCollectionMutation,
  useGetUserCollectionsQuery,
  useRemoveProductsFromCollectionMutation,
  useDeleteCollectionMutation,
  useEditCollectionMutation,
  usePublishManufacturerCollectionMutation,
} from "../../api/collectionsApi";
import { useGetUserClaimsQuery } from "../../api/userApi";
import { useAuth } from "react-oidc-context";
import { CollectionShareDialogContainer } from "../collectionShareDialogContainer/CollectionShareDialogContainer";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface CollectionManagementDialogContainerProps {
  open: boolean;
  products: Product[];
  handleDone: () => void;
  handleClose: () => void;
}

export const CollectionManagementDialogContainer = ({
  products,
  open,
  handleDone,
  handleClose,
}: CollectionManagementDialogContainerProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const manufacturer = useAppSelector((store) => store.manufacturer);

  const { data: collections } = useGetUserCollectionsQuery(manufacturer.Id, {
    pollingInterval: 60000,
    skip: !auth.isAuthenticated,
  });

  const [addProducts] = useAddProductsToCollectionMutation();
  const [removeProducts] = useRemoveProductsFromCollectionMutation();
  const [createUserCollection] = useCreateUserCollectionMutation();
  const [createManufacturerCollection] = useCreateManufacturerCollectionMutation();
  const [editCollection] = useEditCollectionMutation();
  const [publishManufacturerCollection] = usePublishManufacturerCollectionMutation();
  const [deleteCollection] = useDeleteCollectionMutation();

  const { enqueueSnackbar } = useSnackbar();

  const { data: userClaims } = useGetUserClaimsQuery();

  const hasManufacturerCollectionsAccess = userClaims?.ManufacturerCollections?.includes(manufacturer.Id) ?? false;

  const [visibleCollections, setVisibleCollections] = useState([] as Collection[]);
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
  const [loadingCollections, setLoadingCollections] = useState<{ [id: string]: boolean }>({});

  const [collectionFormDialogOpen, setCollectionFormDialogOpen] = useState(false);
  const [collectionShareDialogOpen, setCollectionShareDialogOpen] = useState(false);
  const [collectionDeleteDialogOpen, setCollectionDeleteDialogOpen] = useState(false);

  const [textFilter, setTextFilter] = useState("");
  const [selectedFilterOption, setSelectedFilterOption] = useState<CollectionFilterMenuOption>(
    CollectionFilterMenuOption.All
  );

  const onCollectionFilterMenuOptionClick = (newSelectedFilterOption: CollectionFilterMenuOption) =>
    setSelectedFilterOption(newSelectedFilterOption);
  const onTextFilterChange = (newTextFilter: string) => setTextFilter(newTextFilter);

  useEffect(() => {
    if (collections) {
      const filteredCollections = collections
        .filter((c) => c.Owned)
        .filter((c) => c.Name.toLocaleLowerCase().includes(textFilter.trim().toLocaleLowerCase()))
        .sort((a, b) => a.Name.localeCompare(b.Name));

      const isManufacturerCollection = (collection: Collection) => collection.ManufacturerId !== null;
      const notPublishedCollection = (collection: Collection) =>
        isManufacturerCollection(collection) && collection.Status !== "Published";

      switch (selectedFilterOption) {
        case CollectionFilterMenuOption.All:
          setVisibleCollections(filteredCollections);
          break;
        case CollectionFilterMenuOption.Private:
          setVisibleCollections(filteredCollections.filter((c) => !isManufacturerCollection(c)));
          break;
        case CollectionFilterMenuOption.Manufacturer:
          setVisibleCollections(filteredCollections.filter(isManufacturerCollection));
          break;
        case CollectionFilterMenuOption.NotPublished:
          setVisibleCollections(filteredCollections.filter(notPublishedCollection));
          break;
      }
    }
  }, [collections, manufacturer.Id, selectedFilterOption, textFilter]);

  const collectionFilterMenuOptions = Object.values(CollectionFilterMenuOption);

  const onCollectionEditMenuItemClick = (collection: Collection) => {
    setSelectedCollection(collection);
    setCollectionFormDialogOpen(true);
  };

  const onCollectionPublishMenuItemClick = async (collection: Collection) => {
    if (collection.ManufacturerId) {
      setLoadingCollections({ ...loadingCollections, [collection.ProductSetId]: true });

      await publishManufacturerCollection({
        manufacturerId: collection.ManufacturerId,
        collectionId: collection.ProductSetId,
      });

      setLoadingCollections({ ...loadingCollections, [collection.ProductSetId]: false });

      enqueueSnackbar(t("UI.Collections.Notification.Published"), { variant: "success" });
    }
  };

  const onCollectionDeleteMenuItemClick = (collection: Collection) => {
    setSelectedCollection(collection);
    setCollectionDeleteDialogOpen(true);
  };

  const handleCollectionFormDialogClose = () => {
    setSelectedCollection(null);
    setCollectionFormDialogOpen(false);
  };

  const handleCollectionShareDialogClose = () => {
    setSelectedCollection(null);
    setCollectionShareDialogOpen(false);
  };

  const handleCollectionDeleteDialogClose = () => {
    setSelectedCollection(null);
    setCollectionDeleteDialogOpen(false);
  };

  const handleCollectionFormSubmit = async (data: CollectionFormValues, collectionId?: string) => {
    setCollectionFormDialogOpen(false);
    if (collectionId) {
      setLoadingCollections({ ...loadingCollections, [collectionId]: true });
      await editCollection({ data, collectionId });
      setSelectedCollection(null);
      setLoadingCollections({ ...loadingCollections, [collectionId]: false });
      enqueueSnackbar(t("UI.Collections.Notification.Edited"), { variant: "success" });
    } else {
      data.type === "manufacturer"
        ? await createManufacturerCollection({ data, manufacturerId: manufacturer.Id })
        : await createUserCollection(data);
      enqueueSnackbar(t("UI.Collections.Notification.Created"), { variant: "success" });
    }
  };

  const handleDeleteConfirm = async () => {
    setCollectionDeleteDialogOpen(false);
    if (selectedCollection !== null) {
      setLoadingCollections({ ...loadingCollections, [selectedCollection.ProductSetId]: true });

      await deleteCollection({
        collectionId: selectedCollection.ProductSetId,
        amountOfProducts: selectedCollection.ProductIds.length,
      });

      setSelectedCollection(null);

      setLoadingCollections({ ...loadingCollections, [selectedCollection.ProductSetId]: false });

      enqueueSnackbar(t("UI.Collections.Notification.Deleted"), { variant: "success" });
    }
  };

  const onCollectionShareMenuItemClick = (collection: Collection) => {
    setSelectedCollection(collection);
    setCollectionShareDialogOpen(true);
  };

  const renderCollectionCardMenu = (collection: Collection) => (
    <CollectionCardMenu
      collection={collection}
      onCollectionEditMenuItemClick={onCollectionEditMenuItemClick}
      onCollectionPublishMenuItemClick={onCollectionPublishMenuItemClick}
      onCollectionShareMenuItemClick={onCollectionShareMenuItemClick}
      onCollectionDeleteMenuItemClick={onCollectionDeleteMenuItemClick}
      onCollectionUnfollowMenuItemClick={function (collection: Collection): void {
        throw new Error("Function not implemented.");
      }}
    />
  );

  const handleCollectionCardClick = async (collection: Collection) => {
    setLoadingCollections({ ...loadingCollections, [collection.ProductSetId]: true });

    const selectedProductIds = products.map((p) => p.Id as string);

    const allSelectedProductsExistInCollection = selectedProductIds.every((id) => collection.ProductIds.includes(id));

    if (allSelectedProductsExistInCollection) {
      try {
        await removeProducts({
          collectionId: collection.ProductSetId,
          productIds: selectedProductIds,
        }).unwrap();
        enqueueSnackbar(
          `${products.length} ${
            products.length === 1
              ? t("UI.Collections.Management.CollectionCard.Product")
              : t("UI.Collections.Management.CollectionCard.Products")
          } ${t("UI.Collections.Notification.Removed")}`,
          {
            variant: "success",
          }
        );
      } catch (e) {
        enqueueSnackbar("Internal server error, please try again later", {
          variant: "error",
        });
      }
    } else {
      try {
        await addProducts({ collectionId: collection.ProductSetId, productIds: selectedProductIds }).unwrap();
        enqueueSnackbar(
          `${products.length} ${
            products.length === 1
              ? t("UI.Collections.Management.CollectionCard.Product")
              : t("UI.Collections.Management.CollectionCard.Products")
          } ${t("UI.Collections.Notification.Added")}`,
          {
            variant: "success",
          }
        );
      } catch (e) {
        enqueueSnackbar("Internal server error, please try again later", {
          variant: "error",
        });
      }
    }

    setLoadingCollections({ ...loadingCollections, [collection.ProductSetId]: false });
  };

  const collectionCards = visibleCollections.map((c) => {
    const allSelectedProductsExistInCollection = products
      .map((p) => p.Id as string)
      .every((id) => c.ProductIds.includes(id));

    return (
      <CollectionCard
        key={c.ProductSetId}
        collection={c}
        collectionCardMenu={renderCollectionCardMenu(c)}
        loading={loadingCollections[c.ProductSetId] ?? false}
        selected={allSelectedProductsExistInCollection}
        manufacturerLogoUrl={
          c.ManufacturerId && c.ManufacturerId === manufacturer.Id ? manufacturer.LogoUrl : undefined
        }
        onClick={handleCollectionCardClick}
      />
    );
  });

  const collectionManagementDialogContent = (
    <>
      <CollectionManagementDialogContent
        collectionCards={collectionCards}
        collectionFilterMenuOptions={collectionFilterMenuOptions}
        onCreateNewCollectionButtonClick={() => setCollectionFormDialogOpen(true)}
        onCollectionFilterMenuOptionClick={onCollectionFilterMenuOptionClick}
        onDoneButtonClick={handleDone}
        onTextFilterChange={onTextFilterChange}
      />
      <CollectionFormDialog
        open={collectionFormDialogOpen}
        collection={selectedCollection}
        hasManufacturerCollectionsAccess={hasManufacturerCollectionsAccess}
        handleClose={handleCollectionFormDialogClose}
        onSubmit={handleCollectionFormSubmit}
      />
      {selectedCollection && (
        <CollectionShareDialogContainer
          open={collectionShareDialogOpen}
          collection={selectedCollection}
          handleClose={handleCollectionShareDialogClose}
        />
      )}
      <CollectionDeleteDialog
        open={collectionDeleteDialogOpen}
        handleConfirm={handleDeleteConfirm}
        handleClose={handleCollectionDeleteDialogClose}
      />
    </>
  );

  const title = products.length === 1 ? (products[0].ProductCode as string) : `${products.length} products selected`;

  return (
    <CollectionManagementDialog
      open={open}
      title={title}
      collectionManagementDialogContent={collectionManagementDialogContent}
      handleClose={handleClose}
    />
  );
};
