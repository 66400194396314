import {
  Collection,
  CollectionInvite,
  CollectionShareDialog,
  CollectionShareValues,
} from "@magicad-cloud/component-library";
import { useSnackbar } from "notistack";
import {
  useDeleteInvitationByIdMutation,
  useDeleteMemberMutation,
  useGetCollectionInvitesQuery,
  useInviteMemberMutation,
} from "../../api/collectionsApi";
import { useTranslation } from "react-i18next";
import { getBasePath } from "../../helpers/urlHelpers";

export interface CollectionShareDialogContainerProps {
  collection: Collection;
  open: boolean;
  handleClose: () => void;
}

export const CollectionShareDialogContainer = ({
  collection,
  open,
  handleClose,
}: CollectionShareDialogContainerProps) => {
  const { t } = useTranslation();
  const { data: collectionInvites } = useGetCollectionInvitesQuery(collection.ProductSetId, {
    refetchOnMountOrArgChange: true,
  });

  const [inviteMember] = useInviteMemberMutation();
  const [deleteInvitationById] = useDeleteInvitationByIdMutation();
  const [deleteMember] = useDeleteMemberMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleInviteMember = async ({ email, language }: CollectionShareValues) => {
    try {
      await inviteMember({
        collectionId: collection.ProductSetId,
        email,
        language,
        amountOfProducts: collection.ProductIds.length,
      }).unwrap();
      enqueueSnackbar(t("UI.Collections.Notification.Invited"), { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Internal server error, please try again later", { variant: "error" });
    }
  };

  const handleInviteRemove = async (invite: CollectionInvite) => {
    try {
      if (!invite.Valid) {
        await deleteMember({ collectionId: collection.ProductSetId, userId: invite.UserId }).unwrap();
        enqueueSnackbar(t("UI.Collections.Notification.UserRemoved"), { variant: "success" });
      }
      await deleteInvitationById(invite.InvitationId).unwrap();

      const message = !invite.Valid
        ? t("UI.Collections.Notification.UserRemoved")
        : t("UI.Collections.Notification.InviteDeleted");

      enqueueSnackbar(message, { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Internal server error, please try again later", { variant: "error" });
    }
  };

  return (
    <CollectionShareDialog
      open={open}
      collection={collection}
      collectionBaseUrl={`${window.location.origin}${getBasePath()}/collection`}
      collectionInvites={collectionInvites}
      onInviteSend={handleInviteMember}
      onInviteRemove={handleInviteRemove}
      handleClose={handleClose}
    />
  );
};
