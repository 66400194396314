import { Routes, Route, Navigate } from "react-router-dom";
import { SignInErrorContainer } from "../components/signInErrorContainer/SignInErrorContainer";
import { AuthProvider } from "react-oidc-context";
import { authConfig } from "../config/auth";
import { ProductNotFoundPage } from "../pages/productNotFoundPage/ProductNotFoundPage";
import { BrandedPageApp } from "./BrandedPageApp";
import { MainPage } from "../pages/mainPage/MainPage";
import { ProductDetailsPage } from "../pages/productDetailsPage/ProductDetailsPage";
import "./App.scss";
import { CollectionPage } from "../pages/collectionPage/CollectionPage";

const Router = () => {
  return (
    <AuthProvider {...authConfig}>
      <Routes>
        <Route
          path="/"
          element={
            <BrandedPageApp>
              <MainPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/search/:encodedParams"
          element={
            <BrandedPageApp>
              <MainPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/product/:productId"
          element={
            <BrandedPageApp>
              <ProductDetailsPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/404"
          element={
            <BrandedPageApp>
              <ProductNotFoundPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/home/:manufacturerNameOrId/product"
          element={
            <BrandedPageApp>
              <ProductDetailsPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/home/:manufacturerNameOrId/collection/:collectionId"
          element={
            <BrandedPageApp>
              <CollectionPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/home/:manufacturerNameOrId/product/:productId"
          element={
            <BrandedPageApp>
              <ProductDetailsPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/home/:manufacturerNameOrId/404"
          element={
            <BrandedPageApp>
              <ProductNotFoundPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/home/:manufacturerNameOrId/search/:encodedParams"
          element={
            <BrandedPageApp>
              <MainPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/home/:manufacturerNameOrId/:encodedParams"
          element={
            <BrandedPageApp>
              <MainPage />
            </BrandedPageApp>
          }
        />
        <Route
          path="/home/:manufacturerNameOrId"
          element={
            <BrandedPageApp>
              <MainPage />
            </BrandedPageApp>
          }
        />
        <Route path="/signin" element={<SignInErrorContainer />} />
        <Route
          path="/signout"
          element={<Navigate to={localStorage.getItem("magicadcloud.branded.path") as string} replace />}
        />
        <Route path="*" element={<Navigate to="/404" replace={true} />} />
      </Routes>
    </AuthProvider>
  );
};

export default Router;
