export interface Dataset {
  DatasetEntries: DatasetEntry[];
}

export interface DatasetEntry {
  ClassId: number;
  DeviceType: string;
  ManufacturerAbbr: string;
  ManufacturerName: string;
  MarketAreas: string;
  PartClassId: number;
  ProductQpdId: string;
  SizingMode: number;
  VariantQpdIds: string[];
  Version: string;
  VersionFormated: string;
  VersionNumber: DatasetVersion;
  ProductRef: string;
  ProductCode?: string;
  ProductCodeALL?: string;
}

export interface DatasetEntryFormatted {
  ProductQpdId: string;
  Version: string;
  ProductCode?: string;
  ProductCodeAll?: string;
}

export interface DatasetVersion {
  Build: number;
  Major: number;
  MajorRevision: number;
  Minor: number;
  MinorRevision: number;
  Revision: number;
}

export interface DatasetInsert {
  Cancelled: boolean;
  DatasetEntry: DatasetEntry;
  DatasetResult: string;
  DevMsg: string;
  FriendlyMsg: string;
  Lang: string;
  State: string;
  Success: string;
  UsedFormat: string;
}

export const toDatasetEntryFormatted = (datasetEntry: DatasetEntry): DatasetEntryFormatted => {
  return {
    ProductQpdId: datasetEntry.ProductQpdId,
    Version: datasetEntry.Version,
    ProductCode: datasetEntry.ProductCode,
    ProductCodeAll: datasetEntry.ProductCodeALL,
  };
};

export const datasetToDatasetEntriesFormatted = (dataset: Dataset): DatasetEntryFormatted[] | null => {
  if (dataset != null && dataset.DatasetEntries.length > 0) {
    const result: DatasetEntryFormatted[] = [];
    dataset.DatasetEntries.forEach((item) => result.push(toDatasetEntryFormatted(item)));
    return result;
  }
  return null;
};
