import { useCurrentSearchResults } from "./searchResultsHooks";

interface useOperativeFiltersResponse {
  attenuationEnabled: boolean;
  ventilationOperationPointEnabled: boolean;
}

export const useOperativeFilters = (): useOperativeFiltersResponse => {
  const { searchResults, searchResultsLoading } = useCurrentSearchResults();

  const operativeFiltersEnabled: useOperativeFiltersResponse = {
    attenuationEnabled: false,
    ventilationOperationPointEnabled: false,
  };

  if (!searchResultsLoading) {
    const hasAttenuationOption = searchResults?.OperativeFilters?.some((f) => f.Name === "Attenuation") ?? false;
    const hasVentilationOperationPointOption =
      searchResults?.OperativeFilters?.some((f) => f.Name === "Ventilation operation point") ?? false;

    operativeFiltersEnabled.attenuationEnabled = hasAttenuationOption;
    operativeFiltersEnabled.ventilationOperationPointEnabled = hasVentilationOperationPointOption;
  }

  return operativeFiltersEnabled;
};
