import { useCallback } from "react";
import { ProductRequest, ProductRequestFormValues } from "@magicad-cloud/component-library";
import { useTelemetry } from "../../telemetry-provider-react/telemetryHooks";
import { DownloadRequestEventData } from "../../telemetry-provider-react/types";
import { useAppSelector } from "../../hooks";
import { useAuth } from "react-oidc-context";
import { useLazySendTelemetryEventQuery } from "../../api/searchApi";

interface ProductRequestContainerProps {
  open: boolean;
  handleClose: () => void;
}

export const ProductRequestContainer = ({ open, handleClose }: ProductRequestContainerProps) => {
  const auth = useAuth();
  const { createTelemetryEvent } = useTelemetry();
  const manufacturer = useAppSelector((store) => store.manufacturer);
  const [sendTelemetryEvent] = useLazySendTelemetryEventQuery();

  const sendDownloadRequestTelemetryEvent = useCallback(
    (formData: ProductRequestFormValues) => {
      const manufacturerId = manufacturer.Id;
      const email = auth.user?.profile.email as string;

      const eventData: DownloadRequestEventData = {
        ManufacturerId: manufacturerId,
        ManufacturerName: formData.manufacturerName,
        ProductFamily: formData.productFamily,
        ProductId: "",
        Formats: JSON.stringify(formData.formats),
        Reason: formData.reason,
        ContactSharingAccepted: formData.contactSharingAccepted,
        Email: email,
      };

      const telemetryEvent = createTelemetryEvent({ eventType: "DownloadRequest", eventData });
      void sendTelemetryEvent(telemetryEvent);
      handleClose();
    },
    [auth, createTelemetryEvent, handleClose, manufacturer, sendTelemetryEvent]
  );

  return (
    <ProductRequest
      brandedManufacturer={manufacturer.Name}
      open={open}
      handleClose={handleClose}
      onSubmit={(data) => void sendDownloadRequestTelemetryEvent(data)}
    />
  );
};
