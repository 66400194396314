import { ReactNode } from "react";
import "./ProductDetailsLayout.scss";

export interface ProductDetailsLayoutProps {
  newVersionAlert: ReactNode;
  appBar: ReactNode;
  productDetailsView: ReactNode;
}

export const ProductDetailsLayout = ({ newVersionAlert, appBar, productDetailsView }: ProductDetailsLayoutProps) => {
  return (
    <div className="ProductDetailsLayout">
      <div className="ProductDetailsLayout__alert">{newVersionAlert}</div>
      <div className="ProductDetailsLayout__appbar">{appBar}</div>
      <div className="ProductDetailsLayout__productDetails">{productDetailsView}</div>
    </div>
  );
};
