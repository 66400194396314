import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "@magicad-cloud/component-library";
import { remove } from "lodash";

type multiInsertState = {
  multiInsertProducts: Product[];
  multiInsertSelectEnabled: boolean;
};

const initialState: multiInsertState = {
  multiInsertProducts: [],
  multiInsertSelectEnabled: false,
};

const multiInsertSlice = createSlice({
  name: "multiInsertRequest",
  initialState,
  reducers: {
    addOrRemoveProductFromMultiInsert(state, action: PayloadAction<Product>) {
      const product = action.payload;
      if (!state.multiInsertProducts.some((a) => a.Id === product.Id)) state.multiInsertProducts.push(product);
      else remove(state.multiInsertProducts, (item) => item.Id === product.Id);
    },
    selectProductsForMultiInsert(state, action: PayloadAction<Product[]>) {
      action.payload.forEach((p) => {
        if (!state.multiInsertProducts.some((a) => a.Id === p.Id)) state.multiInsertProducts.push(p);
      });
    },
    clearMultiInsertSelection(state) {
      state.multiInsertProducts = [];
    },
    toggleMultiInsertSelected(state, action: PayloadAction<boolean>) {
      state.multiInsertSelectEnabled = action.payload;
    },
  },
});

export const {
  addOrRemoveProductFromMultiInsert,
  clearMultiInsertSelection,
  selectProductsForMultiInsert,
  toggleMultiInsertSelected,
} = multiInsertSlice.actions;
export default multiInsertSlice.reducer;
