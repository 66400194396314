import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Collection,
  CollectionFormValues,
  CollectionInvite,
  ManufacturerCollection,
} from "@magicad-cloud/component-library";
import { config } from "../config/config";
import { getToken } from "../utils/authUtils";

export const collectionsApi = createApi({
  reducerPath: "collectionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.collectionsApiUrl}/`,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["UserCollections", "ManufacturerCollections", "CollectionInvites"],
  endpoints: (builder) => ({
    getUserCollections: builder.query<Collection[], string>({
      query: () => `productset`,
      transformResponse: (collections: Collection[], _, manufacturerId) =>
        collections.filter((c) => c.ManufacturerId === null || c.ManufacturerId === manufacturerId),
      providesTags: ["UserCollections"],
    }),
    getManufacturerCollections: builder.query<ManufacturerCollection[], string>({
      query: (manufacturerId) => `productset/manufacturer/${manufacturerId}`,
      providesTags: ["ManufacturerCollections"],
    }),
    getCollectionInvites: builder.query<CollectionInvite[], string>({
      query: (collectionId) => `productsetinvitation/${collectionId}`,
      providesTags: ["CollectionInvites"],
    }),
    addProductsToCollection: builder.mutation<void, { collectionId: string; productIds: string[] }>({
      query({ collectionId, productIds }) {
        const body = {
          ProductIds: productIds,
          AmountOfProducts: productIds.length,
        };

        return {
          url: `productset/${collectionId}/product`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["UserCollections", "ManufacturerCollections"],
    }),
    removeProductsFromCollection: builder.mutation<void, { collectionId: string; productIds: string[] }>({
      query({ collectionId, productIds }) {
        const body = {
          ProductIds: productIds,
          AmountOfProducts: productIds.length,
        };

        return {
          url: `productset/${collectionId}/product`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["UserCollections", "ManufacturerCollections"],
    }),
    createUserCollection: builder.mutation<Collection, CollectionFormValues>({
      query({ name, description }) {
        const body = {
          Name: name,
          Description: description,
        };

        return {
          url: `productset`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["UserCollections"],
    }),
    createManufacturerCollection: builder.mutation<Collection, { data: CollectionFormValues; manufacturerId: string }>({
      query({ data: { name, description }, manufacturerId }) {
        const body = {
          Name: name,
          Description: description,
          ManufacturerId: manufacturerId,
        };

        return {
          url: `productset`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["UserCollections", "ManufacturerCollections"],
    }),
    editCollection: builder.mutation<Collection, { data: CollectionFormValues; collectionId: string }>({
      query({ data: { name, description }, collectionId }) {
        const body = {
          ProductSetId: collectionId,
          Name: name,
          Description: description,
        };

        return {
          url: `productset`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UserCollections", "ManufacturerCollections"],
    }),
    publishManufacturerCollection: builder.mutation<void, { manufacturerId: string; collectionId: string }>({
      query({ manufacturerId, collectionId }) {
        return {
          url: `productset/manufacturer/${manufacturerId}/${collectionId}/publish/true`,
          method: "POST",
        };
      },
      invalidatesTags: ["UserCollections", "ManufacturerCollections"],
    }),
    followCollection: builder.mutation<void, string>({
      query(collectionId) {
        return {
          url: `productsetmember/manufacturer/${collectionId}`,
          method: "POST",
        };
      },
      invalidatesTags: ["UserCollections"],
    }),
    unfollowCollection: builder.mutation<void, string>({
      query(collectionId) {
        return {
          url: `productsetmember/manufacturer/${collectionId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["UserCollections"],
    }),
    deleteCollection: builder.mutation<void, { collectionId: string; amountOfProducts: number }>({
      query({ collectionId, amountOfProducts }) {
        const body = {
          AmountOfProducts: amountOfProducts,
        };

        return {
          url: `productset/${collectionId}`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["UserCollections", "ManufacturerCollections"],
    }),
    inviteMember: builder.mutation<
      void,
      { collectionId: string; email: string; language: string; amountOfProducts: number }
    >({
      query({ collectionId, email, language, amountOfProducts }) {
        const body = {
          Email: email,
          Language: language,
          AmountOfProducts: amountOfProducts,
        };

        return {
          url: `productsetmember/invite/${collectionId}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CollectionInvites"],
    }),
    deleteInvitationById: builder.mutation<void, string>({
      query(invitationId) {
        return {
          url: `productsetinvitation/${invitationId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["CollectionInvites"],
    }),
    deleteMember: builder.mutation<void, { collectionId: string; userId: string }>({
      query({ collectionId, userId }) {
        return {
          url: `productsetmember/${collectionId}/${userId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["CollectionInvites"],
    }),
  }),
});

export const {
  useGetUserCollectionsQuery,
  useGetManufacturerCollectionsQuery,
  useGetCollectionInvitesQuery,
  useAddProductsToCollectionMutation,
  useRemoveProductsFromCollectionMutation,
  useCreateUserCollectionMutation,
  useCreateManufacturerCollectionMutation,
  useFollowCollectionMutation,
  useUnfollowCollectionMutation,
  useDeleteCollectionMutation,
  useEditCollectionMutation,
  usePublishManufacturerCollectionMutation,
  useInviteMemberMutation,
  useDeleteInvitationByIdMutation,
  useDeleteMemberMutation,
} = collectionsApi;

export const { useQueryState: useGetManufacturerCollectionsQueryState } =
  collectionsApi.endpoints.getManufacturerCollections;
export const { useQueryState: useGetUserCollectionsQueryState } = collectionsApi.endpoints.getUserCollections;
