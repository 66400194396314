import { useEffect, useState } from "react";
import {
  CollectionControls,
  Product,
  SearchResultCard,
  SearchResultCardSkeleton,
} from "@magicad-cloud/component-library";
import { ProductDetailsView } from "../productDetailsView/ProductDetailsView";
import { CollectionManagementDialogContainer } from "../collectionManagementDialogContainer/CollectionManagementDialogContainer";
import { useAuth } from "react-oidc-context";
import {
  useAppDispatch,
  useAppSelector,
  useDataset,
  useInsert,
  useCurrentSearchResults,
  useProducts,
} from "../../hooks";
import { MultiInsert } from "../multiInsert/MultiInsert";
import { addOrRemoveProductFromMultiInsert } from "../../slices/multiInsertSlice";
import { clearOpenedProduct, setOpenedProduct } from "../../slices/openedProductSlice";
import { getBasePath } from "../../helpers/urlHelpers";
import "./ProductCards.scss";
import { config } from "../../config/config";
import { ProductFromCollectionRemoveDialog } from "../productFromCollectionRemoveDialog/ProductFromCollectionRemoveDialog";
import { useRemoveProductsFromCollectionMutation } from "../../api/collectionsApi";
import { searchApi } from "../../api/searchApi";
import { useGetUserClaimsQuery } from "../../api/userApi";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface ProductCardsProps {
  productRequestOpen: boolean;
  signupDialogOpen: boolean;
  handleProductRequestOpen: () => void;
  handleSignupDialogOpen: () => void;
  onProductRequestClose: () => void;
  onSignupDialogClose: () => void;
}

export const ProductCards = ({
  productRequestOpen,
  signupDialogOpen,
  handleProductRequestOpen,
  handleSignupDialogOpen,
  onProductRequestClose,
  onSignupDialogClose,
}: ProductCardsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const products = useProducts();
  const { searchResults, searchResultsLoading } = useCurrentSearchResults();

  const { insert, isMultiInsertEnabled, isProductSelectedForMultiInsert } = useInsert();
  const manufacturer = useAppSelector((store) => store.manufacturer);
  const { multiInsertSelectEnabled } = useAppSelector((store) => store.multiInsert);
  const applicationType = useAppSelector((state) => state.applicationParams.ApplicationType);
  const connectPlatform = useAppSelector((store) => store.applicationParams.ConnectPlatform);
  const controllerVersion = useAppSelector((state) => state.applicationParams.ControllerVersion);

  const isMagiCad = applicationType === "magicad-branded";

  const [productDetailsPopupOpen, setProductDetailsPopupOpen] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<{ [productId: string]: Product }>({});
  const [collectionControlsOpen, setCollectionControlsOpen] = useState(false);
  const [collectionManagementOpen, setCollectionManagementOpen] = useState(false);
  const [productFromCollectionRemoveDialogOpen, setProductFromCollectionRemoveDialogOpen] = useState(false);

  const { data: userClaims } = useGetUserClaimsQuery();

  const hasManufacturerCollectionsAccess = userClaims?.ManufacturerCollections?.includes(manufacturer.Id) ?? false;

  const selectedManufacturerCollectionId = useAppSelector((store) => store.searchRequest.searchRequest.ProductSetId);
  const [removeProducts] = useRemoveProductsFromCollectionMutation();

  const { productId: openedProductId } = useAppSelector((store) => store.openedProduct);

  const { isFullyInserted, isPartlyInserted, hasUpdateAvailable, hasUpdateAvailableCount } = useDataset();

  useEffect(() => {
    if (!searchResultsLoading && openedProductId) {
      setProductDetailsPopupOpen(true);
    } else {
      setProductDetailsPopupOpen(false);
    }
  }, [searchResultsLoading, openedProductId]);

  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (Object.keys(selectedProducts).length > 0 && !collectionManagementOpen && !productDetailsPopupOpen) {
      closeSnackbar();
      setCollectionControlsOpen(true);
    } else {
      setCollectionControlsOpen(false);
    }
  }, [closeSnackbar, collectionManagementOpen, productDetailsPopupOpen, selectedProducts]);

  useEffect(() => {
    setCollectionControlsOpen(false);
    setSelectedProducts({});
  }, [searchResults]);

  const handleOpen = (product: Product) => {
    dispatch(setOpenedProduct(product.Id ?? null));
    setProductDetailsPopupOpen(true);
  };

  const handleClose = () => {
    dispatch(clearOpenedProduct());
    setProductDetailsPopupOpen(false);
  };

  const handleCollectionsClick = (product: Product) => {
    if (auth.isAuthenticated) {
      const productId = product.Id as string;
      if (selectedProducts[productId]) {
        const { [productId]: _productToRemove, ...rest } = selectedProducts;
        setSelectedProducts(rest);
      } else {
        setSelectedProducts({ ...selectedProducts, [productId]: product });
      }
    } else {
      handleSignupDialogOpen();
    }
  };

  const handleInsert = (product: Product) => {
    if (!isMagiCad) {
      insert(product);
      return;
    }
    if (product.Classifications && product.Classifications["MagiCAD"].MagiCadDisciplines) {
      switch (product.Classifications["MagiCAD"].MagiCadDisciplines[0]) {
        case "L;8":
          if (product?.VariantCount && product?.VariantCount < 2) {
            insert(product);
          } else {
            handleOpen(product);
          }
          return;
        default:
          insert(product);
          return;
      }
    }
  };

  const handleSelectAllProducts = () => {
    setSelectedProducts(
      products.reduce((acc, curr) => {
        acc[curr.Id as string] = curr;
        return acc;
      }, {} as { [productId: string]: Product })
    );
  };

  const handleDone = () => {
    setCollectionManagementOpen(false);
    setSelectedProducts({});
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleProductsFromCollectionRemove = async () => {
    if (hasManufacturerCollectionsAccess && selectedManufacturerCollectionId) {
      const productIdsToRemove = Object.keys(selectedProducts);

      try {
        await removeProducts({
          collectionId: selectedManufacturerCollectionId,
          productIds: productIdsToRemove,
        }).unwrap();
        setProductFromCollectionRemoveDialogOpen(false);
        dispatch(searchApi.util.invalidateTags(["SearchResults"]));
        enqueueSnackbar(
          `${productIdsToRemove.length} ${
            productIdsToRemove.length === 1
              ? t("UI.Collections.Management.CollectionCard.Product")
              : t("UI.Collections.Management.CollectionCard.Products")
          } ${t("UI.Collections.Notification.Removed")}`,
          { variant: "success" }
        );
      } catch (e) {
        enqueueSnackbar("Internal server error, please try again later", { variant: "error" });
      }
    }
  };

  const productCardSkeletons = Array(50).fill(null);

  const renderProductCardSkeletons = () => (
    <>
      {productCardSkeletons.map((_: null, index) => (
        <div key={index}>
          <SearchResultCardSkeleton />
        </div>
      ))}
    </>
  );

  const basePath = getBasePath();

  const renderProductCards = () => (
    <>
      {products.map((p) => (
        <div key={p.Id} className="ProductCard">
          <a
            href={`${basePath}/product/${p.Id ?? ""}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            className="SearchResultCard__anchor"
          >
            <SearchResultCard
              product={p}
              onOpen={handleOpen}
              onCollections={handleCollectionsClick}
              selectedForCollections={!!selectedProducts[p.Id as string]}
              multiSelectModeOn={isMultiInsertEnabled && multiInsertSelectEnabled}
              isInserted={isFullyInserted(p)}
              onInsert={handleInsert}
              isPartiallyInserted={isPartlyInserted(p) > 0}
              partiallyInsertedCount={isPartlyInserted(p)}
              onMultiSelect={() => {
                dispatch(addOrRemoveProductFromMultiInsert(p));
              }}
              selectedForMultiSelect={isProductSelectedForMultiInsert(p)}
              hasUpdateAvailable={hasUpdateAvailable(p)}
              hasUpdateAvailableCount={hasUpdateAvailableCount(p)}
              allowReinsert={
                config.connectAllowReinsertVersion < new Date(controllerVersion ?? new Date("1970.1.1")) &&
                connectPlatform === "MRV"
              }
            />
          </a>
        </div>
      ))}
      <CollectionControls
        open={collectionControlsOpen}
        amountOfProductsSelected={Object.keys(selectedProducts).length}
        onAddToCollectionButtonClick={() => setCollectionManagementOpen(true)}
        onRemoveFromCollectionButtonClick={
          hasManufacturerCollectionsAccess && selectedManufacturerCollectionId
            ? () => setProductFromCollectionRemoveDialogOpen(true)
            : undefined
        }
        onSelectAllButtonClick={handleSelectAllProducts}
        onResetSelectionButtonClick={() => setSelectedProducts({})}
      />
      {!searchResultsLoading && openedProductId && productDetailsPopupOpen ? (
        <ProductDetailsView
          productId={openedProductId}
          isOpen={productDetailsPopupOpen}
          productRequestOpen={productRequestOpen}
          onClose={handleClose}
          handleProductRequestOpen={handleProductRequestOpen}
          onProductRequestClose={onProductRequestClose}
          signupDialogOpen={signupDialogOpen}
          handleSignupDialogOpen={handleSignupDialogOpen}
          onSignupDialogClose={onSignupDialogClose}
        />
      ) : null}
      <CollectionManagementDialogContainer
        open={collectionManagementOpen}
        products={Object.values(selectedProducts)}
        handleDone={handleDone}
        handleClose={() => setCollectionManagementOpen(false)}
      />
      {isMultiInsertEnabled && <MultiInsert products={products} />}
      <ProductFromCollectionRemoveDialog
        open={productFromCollectionRemoveDialogOpen}
        handleConfirm={handleProductsFromCollectionRemove}
        handleClose={() => setProductFromCollectionRemoveDialogOpen(false)}
      />
    </>
  );

  return searchResultsLoading ? renderProductCardSkeletons() : renderProductCards();
};
