import { ProductAction, Download } from "@magicad-cloud/component-library";
import { useAccessToken } from "./authHooks";
import fileDownload from "js-file-download";
import { parse } from "content-disposition-header";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { decreaseRequestsLeft } from "../slices/subscriptionSlice";

const downloadAuthenticated = async (url: string, accessToken: string): Promise<void> => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const disposition = parse(response.headers.get("content-disposition") ?? "");
  const filename = disposition.parameters.filename;

  const blob = await response.blob();
  fileDownload(blob, filename);
};

export function useDownloads() {
  const { fetchAccessToken } = useAccessToken();
  const dispatch = useAppDispatch();
  const { requestsLeft } = useAppSelector((store) => store.subscription);

  const downloadProduct = useCallback(
    async (downloadAction: ProductAction, selectedDownload: Download | null): Promise<boolean> => {
      const downloadUrl = selectedDownload ? selectedDownload.DownloadUrl : downloadAction.DownloadUrl;
      let showNotification = false;

      if (!downloadUrl) throw new Error("Invalid download url");

      const accessToken = await fetchAccessToken();
      if (!accessToken) throw new Error("Invalid access token");

      if (downloadAction.FileExtension !== "DXF") {
        if (requestsLeft <= 0) return true;

        // show a notification if after this download there are 0, 5, 10 or 15 downloads left
        if ([0, 5, 10, 15].includes(requestsLeft - 1)) showNotification = true;
        dispatch(decreaseRequestsLeft());
      }

      await downloadAuthenticated(downloadUrl, accessToken);
      return showNotification;
    },
    [dispatch, fetchAccessToken, requestsLeft]
  );

  return { downloadProduct };
}
