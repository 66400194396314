import { ProductRequestNotification } from "@magicad-cloud/component-library";
import { Skeleton, Typography } from "@mui/material";
import { useCurrentSearchResults } from "../../hooks";
import { t } from "i18next";
import "./ResultInfo.scss";

interface ResultInfoProps {
  handleProductRequestOpen: () => void;
}

export const ResultInfo = ({ handleProductRequestOpen }: ResultInfoProps) => {
  const { searchResults, searchResultsLoading } = useCurrentSearchResults();

  if (searchResultsLoading || !searchResults) {
    return <Skeleton variant="text" width={480} height={28} />;
  }

  if (!searchResultsLoading && searchResults && searchResults?.Total === 0) {
    return <ProductRequestNotification handleOpen={handleProductRequestOpen} />;
  }

  const total = searchResults.Total > 10000 ? "10000+" : searchResults.Total.toString();

  return (
    <Typography variant="subtitle1">
      {`${t("UI.SearchResults")}: ${total} ${t("UI.SearchResults.Products")} (${searchResults.TotalVariants} ${t(
        "UI.SearchResults.Variants"
      )})`}
    </Typography>
  );
};
