import { useState } from "react";
import { FacetDrawerControls } from "../components/filters/facetFilters/FacetFilters";

export const useFacetDrawerControls = (): FacetDrawerControls => {
  const [facetDrawerOpen, setFacetDrawerOpen] = useState(false);
  const [productGroupsExpanded, setProductGroupsExpanded] = useState(false);

  const handleFacetDrawerOpen = () => {
    setFacetDrawerOpen(true);
  };

  const handleFacetDrawerClose = () => {
    handleProductGroupsExpand(false);
    setFacetDrawerOpen(false);
  };

  const handleProductGroupsExpand = (expanded: boolean) => setProductGroupsExpanded(expanded);

  return {
    facetDrawerOpen,
    productGroupsExpanded,
    handleFacetDrawerOpen,
    handleFacetDrawerClose,
    handleProductGroupsExpand,
  };
};
