import { searchApi } from "../api/searchApi";
import { Variant, MatchingVariants } from "@magicad-cloud/component-library";
import { useAppSelector } from "./reduxHooks";
import { useCallback } from "react";

interface useVariantsResponse {
  getMatchingVariants: (from: number, size: number) => Promise<MatchingVariants | undefined>;
  getVariants: (from: number, size: number) => Promise<Variant[] | undefined>;
}

export function useVariants(productId: string): useVariantsResponse {
  const { searchRequest } = useAppSelector((store) => store.searchRequest);
  const [matchingVariantsTrigger] = searchApi.endpoints.getMatchingVariants.useLazyQuery();
  const [variantsTrigger] = searchApi.endpoints.getVariants.useLazyQuery();

  const getMatchingVariants = useCallback(
    async (from: number, size: number): Promise<MatchingVariants | undefined> => {
      const { data: matchingVariants } = await matchingVariantsTrigger(
        {
          ExternalReference: productId,
          From: from,
          Size: size,
          SearchRequest: searchRequest,
        },
        true
      );
      return matchingVariants;
    },
    [productId, searchRequest, matchingVariantsTrigger]
  );

  const getVariants = useCallback(
    async (from: number, size: number): Promise<Variant[] | undefined> => {
      const { data: variants } = await variantsTrigger(
        {
          From: from,
          Size: size,
          LanguageLocale: searchRequest.LanguageLocale,
          ProductId: productId,
          ProductClassIds: null,
          MarketAreaID: searchRequest.MarketAreas[0] ? searchRequest.MarketAreas[0] : "ALL",
        },
        true
      );
      return variants;
    },
    [productId, searchRequest, variantsTrigger]
  );

  return { getMatchingVariants, getVariants };
}
