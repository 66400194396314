import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Manufacturer, Product, MatchingVariants, Variant } from "@magicad-cloud/component-library";
import { MatchingVariantsRequest, ProductDetailsRequest, VariantsRequest } from "../common/types";
import { config } from "../config/config";
import { getToken } from "../utils/authUtils";
import { TelemetryEvent } from "../telemetry-provider-react/types";
import { SearchOptionsRequest } from "../models/searchOptionsRequest";
import { SearchResults } from "../models/searchResults";
import { SearchOptionsResult } from "../models/searchOptionsResult";
import { SearchRequest, SearchRequestWithTelemetryProperties } from "../models/searchRequest";
import { DatasetEntryFormatted } from "../models/dataset";

export const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.searchApiUrl}/`,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["SearchResults"],
  endpoints: (builder) => ({
    getManufacturer: builder.query<Manufacturer, string>({
      query: (nameOrId) => `manufacturer/branded/${nameOrId}`,
    }),
    getSearchOptions: builder.query<SearchOptionsResult, SearchOptionsRequest>({
      query: (searchOptionsRequest) => ({
        url: "search/options/branded",
        method: "POST",
        body: searchOptionsRequest,
      }),
    }),
    getSearchResult: builder.query<SearchResults, SearchRequestWithTelemetryProperties>({
      query: (searchRequest: SearchRequestWithTelemetryProperties) => ({
        url: "search",
        method: "POST",
        body: searchRequest,
      }),
      providesTags: ["SearchResults"],
    }),
    getProductDetails: builder.query<Product, ProductDetailsRequest>({
      query: (productRequest: ProductDetailsRequest) => ({
        url: "productdata/details",
        method: "POST",
        body: productRequest,
      }),
    }),
    getMatchingVariants: builder.query<MatchingVariants, MatchingVariantsRequest>({
      query: (matchingVariantsRequest: MatchingVariantsRequest) => ({
        url: "productdata/matchingvariants",
        method: "POST",
        body: matchingVariantsRequest,
      }),
    }),
    getVariants: builder.query<Variant[], VariantsRequest>({
      query: (variantsRequest: VariantsRequest) => ({
        url: "productdata/variants",
        method: "POST",
        body: variantsRequest,
      }),
    }),
    sendTelemetryEvent: builder.query<void, TelemetryEvent>({
      query: (event: TelemetryEvent) => ({
        url: "events/send",
        method: "POST",
        body: event,
      }),
    }),
    sendMultipleTelemetryEvents: builder.query<void, TelemetryEvent[]>({
      query: (events: TelemetryEvent[]) => ({
        url: "events/sendmultiple",
        method: "POST",
        body: events,
      }),
    }),
    checkIfProductUpdatesAreAvailable: builder.query<boolean, SearchRequest>({
      query: (searchRequest) => ({
        url: `connect/arenewproductversionsavailable`,
        method: "POST",
        body: searchRequest,
      }),
    }),
  }),
});

export const {
  useGetManufacturerQuery,
  useGetSearchOptionsQuery,
  useGetSearchResultQuery,
  useGetProductDetailsQuery,
  useGetMatchingVariantsQuery,
  useGetVariantsQuery,
  useCheckIfProductUpdatesAreAvailableQuery,
} = searchApi;
export const { useQueryState: useGetManufacturerQueryState } = searchApi.endpoints.getManufacturer;
export const { useQueryState: useGetSearchOptionsQueryState } = searchApi.endpoints.getSearchOptions;
export const { useQueryState: useGetSearchResultQueryState } = searchApi.endpoints.getSearchResult;
export const useLazySendTelemetryEventQuery = searchApi.useLazySendTelemetryEventQuery;
export const useLazySendMultipleTelemetryEventsQuery = searchApi.useLazySendMultipleTelemetryEventsQuery;
