import { useAppDispatch, useAppSelector, useInsert } from "../../hooks";
import { MultiInsert as MInsert, Product } from "@magicad-cloud/component-library";
import { GlobalHotKeys } from "react-hotkeys";
import {
  clearMultiInsertSelection,
  selectProductsForMultiInsert,
  toggleMultiInsertSelected,
} from "../../slices/multiInsertSlice";

interface MultiInsertProps {
  products: Product[];
}

export const MultiInsert = ({ products }: MultiInsertProps) => {
  const { multiInsert } = useInsert();
  const { multiInsertProducts, multiInsertSelectEnabled } = useAppSelector((store) => store.multiInsert);
  const dispatch = useAppDispatch();

  const handleSelectProducts = () => {
    dispatch(toggleMultiInsertSelected(!multiInsertSelectEnabled));
  };
  const handleSelectAllOnCurrentPage = () => {
    dispatch(selectProductsForMultiInsert(products));
    dispatch(toggleMultiInsertSelected(true));
  };
  const handleResetSelection = () => {
    dispatch(clearMultiInsertSelection());
  };
  const handleInsertSelected = () => {
    multiInsert();
  };

  return (
    <GlobalHotKeys
      keyMap={{ SelectProducts: "Alt" }}
      handlers={{ SelectProducts: handleSelectProducts }}
      allowChanges={true}
    >
      <MInsert
        handleSelectProducts={handleSelectProducts}
        handleSelectAllOnCurrentPage={handleSelectAllOnCurrentPage}
        handleResetSelection={handleResetSelection}
        handleInsertSelected={handleInsertSelected}
        variant={"persistent"}
        button={true}
        buttonDisabled={false}
        selectEnabled={multiInsertSelectEnabled}
        productsSelected={multiInsertProducts.length !== 0}
        allProductsSelected={multiInsertProducts.length >= products.length}
      />
    </GlobalHotKeys>
  );
};
