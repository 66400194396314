import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { VariantFeatureCollectionRequest } from "../common/types";
import { VariantFeatureCollection } from "@magicad-cloud/component-library";
import { config } from "../config/config";
import { getToken } from "../utils/authUtils";

export const productDataApi = createApi({
  reducerPath: "productDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.productDataApiUrl}/`,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getVariantFeatures: builder.query<VariantFeatureCollection, VariantFeatureCollectionRequest>({
      query: (variantFeaturesCollectionRequest) => ({
        url: "variantfeatures/all",
        method: "POST",
        body: variantFeaturesCollectionRequest,
      }),
    }),
  }),
});

export const { useGetVariantFeaturesQuery } = productDataApi;
