import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedUnits, DefaultSelectedUnits, DefaultUnits } from "@magicad-cloud/component-library";
import { cloneDeep, findKey } from "lodash";
import { ConnectParams } from "../models/connectParams";

const localStorageKey = "selected-units";

type MeasurementUnitsState = {
  selectedUnits: SelectedUnits;
};

const initializeUnits = (): SelectedUnits => {
  const storedUnits = localStorage.getItem(localStorageKey);
  if (!storedUnits) return cloneDeep(DefaultSelectedUnits);

  return JSON.parse(storedUnits) as SelectedUnits;
};

const initialState: MeasurementUnitsState = {
  selectedUnits: initializeUnits(),
};

const getConnectUnits = (selectedUnits: SelectedUnits, connectParams: ConnectParams): SelectedUnits => {
  const units = cloneDeep(selectedUnits);

  applyUnit("PipingFlowUnits", connectParams.pipingunits?.qv, units);
  applyUnit("PipingPressureUnits", connectParams.pipingunits?.dp, units);
  applyUnit("PipingPowerUnits", connectParams.radiatorunits?.power, units);
  applyUnit("VentilationFlowUnits", connectParams.ventilationunits?.qv, units);
  applyUnit("VentilationPressureUnits", connectParams.ventilationunits?.dp, units);

  return units;
};

const applyUnit = (unitName: string, unitValue: number | undefined, selectedUnits: SelectedUnits) => {
  if (unitValue) {
    const unitKey = findKey(DefaultUnits[unitName].ValueSet, (unit) => unit.NumericValue === unitValue);
    if (unitKey) selectedUnits[unitName] = DefaultUnits[unitName].ValueSet[unitKey].StringValue;
  }
};

const measurementUnitsSlice = createSlice({
  name: "measurementUnits",
  initialState,
  reducers: {
    initMeasurementUnits(state, { payload: connectParams }: PayloadAction<ConnectParams>) {
      if (connectParams.pipingunits || connectParams.radiatorunits || connectParams.ventilationunits) {
        const units = getConnectUnits(state.selectedUnits, connectParams);
        localStorage.setItem(localStorageKey, JSON.stringify(units));
        state.selectedUnits = units;
      }
    },
    updateSelectedUnits(state, action: PayloadAction<SelectedUnits>) {
      const units = action.payload;
      localStorage.setItem(localStorageKey, JSON.stringify(units));
      state.selectedUnits = action.payload;
    },
  },
});

export const { initMeasurementUnits, updateSelectedUnits } = measurementUnitsSlice.actions;

export default measurementUnitsSlice.reducer;
