import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionStatus } from "@magicad-cloud/component-library";
import { SubscriptionResponse } from "../models/subscription";

export interface SubscriptionState {
  requestsLeft: number;
  subscriptionStatus: SubscriptionStatus;
}

const initialState: SubscriptionState = {
  requestsLeft: 0,
  subscriptionStatus: "notLoggedIn",
};

const createSubscriptionStatus = (requestsLeft: number) => {
  return requestsLeft > 0 ? "downloadAllowed" : "subscriptionFull";
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setFromSubscriptionResponse(state, action: PayloadAction<SubscriptionResponse>) {
      const subscription = action.payload.Subscriptions.filter(
        (subscription) => subscription.Name === "MagiCloud"
      ).sort((a, b) => b.Level - a.Level)[0];
      const download = action.payload.Downloads.find(
        (download) => download.SubscriptionId === subscription?.Identifier
      );

      const downloadLimit = subscription.DownloadLimit.PerWeek;
      const totalRequests = download?.TotalRequests ?? 0;
      state.requestsLeft = downloadLimit - totalRequests;

      state.subscriptionStatus = createSubscriptionStatus(state.requestsLeft);
    },
    decreaseRequestsLeft(state) {
      state.requestsLeft--;
      state.subscriptionStatus = createSubscriptionStatus(state.requestsLeft);
    },
    clearSubscription() {
      return initialState;
    },
  },
});

export const { setFromSubscriptionResponse, decreaseRequestsLeft, clearSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
