import { Fragment, useCallback, useEffect, useState } from "react";
import {
  ProductDetails,
  ProductAction,
  Download,
  SelectedUnits,
  Variant,
  VariantsResult,
  Product,
  Diagram,
  ProductDetailsProps,
} from "@magicad-cloud/component-library";
import { searchApi, useLazySendTelemetryEventQuery } from "../../api/searchApi";
import { Dialog, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector, useDataset, useDownloads, useInsert, useVariants } from "../../hooks";
import { updateSelectedUnits } from "../../slices/measurementUnitsSlice";
import { useGetVariantFeaturesQuery } from "../../api/productDataApi";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { setOpenedVariant } from "../../slices/openedProductSlice";
import { Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isNull } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { ProductRequestContainer } from "../productRequestContainer/ProductRequestContainer";
import { useAuth } from "react-oidc-context";
import { useTelemetry } from "../../telemetry-provider-react/telemetryHooks";
import {
  ProductCardDiagramClickedEventData,
  ProductCardOpenedEventData,
  ProductCardPermalinkUsedEventData,
} from "../../telemetry-provider-react/types";
import { SignupDialogContainer } from "../signupDialogContainer/SignupDialogContainer";
import { config } from "../../config/config";
import { Navigate } from "react-router-dom";
import { getBasePath } from "../../helpers/urlHelpers";
import "./ProductDetailsView.scss";

interface ProductDetailsViewProps {
  productId: string;
  isOpen?: boolean;
  productRequestOpen: boolean;
  signupDialogOpen: boolean;
  onClose?: () => void;
  handleProductRequestOpen: () => void;
  handleSignupDialogOpen: () => void;
  onProductRequestClose: () => void;
  onSignupDialogClose: () => void;
}

export const ProductDetailsView = ({
  productId,
  isOpen,
  productRequestOpen,
  signupDialogOpen,
  onClose,
  handleProductRequestOpen,
  handleSignupDialogOpen,
  onProductRequestClose,
  onSignupDialogClose,
}: ProductDetailsViewProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const selectedUnits = useAppSelector((store) => store.measurementUnits.selectedUnits);

  const connectPlatform = useAppSelector((store) => store.applicationParams.ConnectPlatform);
  const controllerVersion = useAppSelector((state) => state.applicationParams.ControllerVersion);

  const manufacturer = useAppSelector((store) => store.manufacturer);

  const permalinkBaseUrl = `${config.redirectUrl}/${manufacturer.UrlFriendlyName}`;

  const [productDetails, setProductDetails] = useState<Product | null>(null);
  const [productDetailsLoading, setProductDetailsLoading] = useState(true);
  const [productDetailsNotFound, setProductDetailsNotFound] = useState(false);

  const [subscriptionPopupOpen, setSubscriptionPopupOpen] = useState(false);
  const handleSubscriptionPopupClose = () => setSubscriptionPopupOpen(false);

  const [totalVariantCount, setTotalVariantCount] = useState(0);
  const [variantsResult, setVariantsResult] = useState<VariantsResult | null>(null);
  const [variantsLoading, setVariantsLoading] = useState(false);
  const { getMatchingVariants, getVariants } = useVariants(productId);
  const [productTrigger] = searchApi.endpoints.getProductDetails.useLazyQuery();
  const { downloadProduct } = useDownloads();
  const { insert, closeConnect } = useInsert();
  const { isInserted, hasUpdateAvailableDetails, hasUpdateAvailableVariant } = useDataset();

  const { searchRequest } = useAppSelector((store) => store.searchRequest);

  const { variantId: selectedVariantId } = useAppSelector((store) => store.openedProduct);
  const { requestsLeft, subscriptionStatus } = useAppSelector((store) => store.subscription);

  const { createTelemetryEvent } = useTelemetry();
  const [sendTelemetryEvent] = useLazySendTelemetryEventQuery();

  const sendProductCardLoadedTelemetryEvent = useCallback(
    (productDetails: Product) => {
      const eventData: ProductCardOpenedEventData = {
        ProductId: productDetails.ProductId ?? "",
        ProductType: ((productDetails.Classifications || {}).MagiCAD || {}).MagiCadClassId || null,
        ManufacturerId: productDetails.ManufacturerId || "",
        DiagramClicked: null,
        PermalinkUsed: false,
        DownloadRequest: null,
      };

      const telemetryEvent = createTelemetryEvent({ eventType: "ProductCard", eventData });
      void sendTelemetryEvent(telemetryEvent);
    },
    [createTelemetryEvent, sendTelemetryEvent]
  );

  const sendPermalinkUsedTelemetryEvent = useCallback(
    (productDetails: Product) => {
      const eventData: ProductCardPermalinkUsedEventData = {
        ProductId: productDetails.ProductId ?? "",
        ProductType: ((productDetails.Classifications || {}).MagiCAD || {}).MagiCadClassId || null,
        ManufacturerId: productDetails.ManufacturerId || "",
        PermalinkUsed: true,
      };

      const telemetryEvent = createTelemetryEvent({ eventType: "ProductCard", eventData });
      void sendTelemetryEvent(telemetryEvent);
    },
    [createTelemetryEvent, sendTelemetryEvent]
  );

  const sendDiagramClickedTelemetryEvent = useCallback(
    (productDetails: Product, diagram: Diagram) => {
      const eventData: ProductCardDiagramClickedEventData = {
        ProductId: productDetails.ProductId ?? "",
        ProductType: ((productDetails.Classifications || {}).MagiCAD || {}).MagiCadClassId || null,
        ManufacturerId: productDetails.ManufacturerId || "",
        DiagramClicked: diagram.Type,
      };

      const telemetryEvent = createTelemetryEvent({ eventType: "ProductCard", eventData });
      void sendTelemetryEvent(telemetryEvent);
    },
    [createTelemetryEvent, sendTelemetryEvent]
  );

  const sendDownloadTelemetryEvent = useCallback(
    (action: ProductAction, selectedDownload: Download | null) => {
      const eventData = {
        ProductId: productDetails?.ProductId,
        ProductType: ((productDetails?.Classifications || {}).MagiCAD || {}).MagiCadClassId,
        ManufacturerId: productDetails?.ManufacturerId,
        Format: action.DownloadName ?? selectedDownload?.DownloadName,
      };
      const telemetryEvent = createTelemetryEvent({ eventType: "Download", eventData });
      void sendTelemetryEvent(telemetryEvent);
    },
    [createTelemetryEvent, productDetails, sendTelemetryEvent]
  );

  useEffect(() => {
    setProductDetailsLoading(true);

    if (isNull(selectedVariantId)) {
      void fetchInitialVariantId();
    } else {
      fetchProductDetails().finally(() => {
        setProductDetailsLoading(false);
      });
    }

    async function fetchInitialVariantId() {
      const matchingVariants = await getMatchingVariants(0, 50);
      const variantId = matchingVariants?.Results[0]?.Id ?? "";
      dispatch(setOpenedVariant(variantId));
    }

    async function fetchProductDetails() {
      const { data: productData, error: productDataError } = await productTrigger(
        {
          HideUserActions: false,
          LanguageLocale: searchRequest.LanguageLocale,
          MarketAreas: searchRequest.MarketAreas,
          ProductId: productId,
          VariantId: selectedVariantId ?? "",
          Applications:
            searchRequest.Applications && searchRequest.Applications.length > 0
              ? searchRequest.Applications
              : undefined,
        },
        true
      );

      const manufacturersDoNotMatch = manufacturer.Id !== productData?.ManufacturerId;

      if (
        manufacturersDoNotMatch ||
        (productDataError && "status" in productDataError && productDataError?.status === 404)
      ) {
        setProductDetailsNotFound(true);
      }

      setTotalVariantCount(productData?.VariantTotalCount ?? 0);
      setProductDetails(productData ?? null);
    }
  }, [productTrigger, searchRequest, getMatchingVariants, productId, selectedVariantId, dispatch, manufacturer.Id]);

  useEffect(() => {
    if (!productDetailsLoading && productDetails) void sendProductCardLoadedTelemetryEvent(productDetails);
  }, [productDetailsLoading, productDetails, sendProductCardLoadedTelemetryEvent]);

  useEffect(() => {
    if (totalVariantCount < 2) {
      setVariantsLoading(false);
      setVariantsResult(null);
      return;
    }
    setVariantsLoading(true);

    async function fetchVariants() {
      const matchingVariants = await getMatchingVariants(0, 10000);

      if (matchingVariants && matchingVariants.Total > 0) {
        const matchingVariantsResults = matchingVariants.Results.map((variant) => ({
          ...variant,
          IsInserted: isInserted(variant.QpdId),
          HasUpdateAvailable: hasUpdateAvailableVariant(variant.QpdId, productDetails?.Edited),
        }));

        setVariantsResult({
          TotalMatchingVariants: matchingVariants.Total,
          TotalVariants: totalVariantCount,
          Variants: matchingVariantsResults,
          IsMatchingOnly: matchingVariants.Total !== totalVariantCount,
        });
        return;
      }

      const variants = await getVariants(0, 50);
      if (variants) {
        const variantsResults = variants.map((variant) => ({
          ...variant,
          IsInserted: isInserted(variant.QpdId),
          HasUpdateAvailable: hasUpdateAvailableVariant(variant.QpdId, productDetails?.Edited),
        }));
        setVariantsResult({
          TotalMatchingVariants: matchingVariants?.Total ?? 0,
          TotalVariants: totalVariantCount,
          Variants: variantsResults,
          IsMatchingOnly: false,
        });
        return;
      }
      setVariantsResult(null);
    }
    void fetchVariants().finally(() => setVariantsLoading(false));
  }, [
    getMatchingVariants,
    getVariants,
    totalVariantCount,
    isInserted,
    productDetails?.Edited,
    hasUpdateAvailableVariant,
  ]);

  const requestVariants = (matchingOnly: boolean, start: number, amount?: number) => {
    setVariantsLoading(true);

    updateVariants().finally(() => setVariantsLoading(false));

    async function updateVariants() {
      if (matchingOnly) {
        const matchingVariants = await getMatchingVariants(start, amount ?? 50);
        if (matchingVariants)
          setVariantsResult({
            TotalMatchingVariants: matchingVariants.Total,
            TotalVariants: totalVariantCount,
            Variants: matchingVariants.Results,
            IsMatchingOnly: true,
            VariantsStart: start,
          });
        return;
      }

      const variants = await getVariants(start, amount ?? 50);
      if (variants)
        setVariantsResult({
          TotalMatchingVariants: variantsResult?.TotalMatchingVariants ?? 0,
          TotalVariants: totalVariantCount,
          Variants: variants,
          IsMatchingOnly: false,
          VariantsStart: start,
        });
    }
  };

  const variantFeaturesCollectionRequest = {
    locale: "EN",
    productExternalReference: productDetails?.ProductId ?? "",
    variantQpdId: productDetails?.QpdId ?? "",
  };

  const { data: variantFeatureCollection = null } = useGetVariantFeaturesQuery(
    productDetails ? variantFeaturesCollectionRequest : skipToken
  );

  const onAction = (action: ProductAction, selectedDownload: Download | null, callback: () => void) => {
    if (action.ActionName === "insert") {
      insert(productDetails);
      callback();
      return;
    }
    if (action.ActionName === "download") {
      if (!auth.isAuthenticated) {
        handleSignupDialogOpen();
      } else {
        downloadProduct(action, selectedDownload)
          .then((showNotification) => {
            void sendDownloadTelemetryEvent(action, selectedDownload);
            if (showNotification) setSubscriptionPopupOpen(true);
          })
          .finally(() => {
            callback();
          });
      }
    }
    if (action.ActionName === "request") {
      handleProductRequestOpen();
      callback();
      return;
    }
  };

  const onUnitSelection = (selectedUnits: SelectedUnits) => dispatch(updateSelectedUnits(selectedUnits));
  const onChangeVariant = (variant: Variant) => {
    dispatch(setOpenedVariant(variant.Id));
  };

  const _isInserted = () => {
    if (productDetails) return isInserted(productDetails.FamilyQpdId, productDetails.QpdId);
    return false;
  };

  const _hasUpdateAvailable = () => {
    if (productDetails) return hasUpdateAvailableDetails(productDetails);
    return false;
  };

  const snackbar = (
    <Snackbar
      className="ProductDetailsView__snackbar"
      open={subscriptionPopupOpen}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleSubscriptionPopupClose}
      message={requestsLeft.toString() + " " + t("UI.Notification.Message.Downloads.Postfix")}
      action={
        <IconButton size="small" color="inherit" onClick={handleSubscriptionPopupClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );

  const productDetailsProps: ProductDetailsProps = {
    product: productDetails,
    permalinkBaseUrl: permalinkBaseUrl,
    onAction: onAction,
    onUnitSelection: onUnitSelection,
    variantFeatures: variantFeatureCollection,
    variantsResult: variantsResult,
    onChangeVariant: onChangeVariant,
    selectedUnits: selectedUnits,
    variantsLoading: variantsLoading,
    requestVariants: requestVariants,
    productLoading: productDetailsLoading && !productDetails,
    onClose: onClose,
    onConnectCloseAction: closeConnect,
    changingVariant: productDetailsLoading && !!productDetails,
    collapseStyleHackEnabled: false,
    selectedVariantId: selectedVariantId,
    isInserted: _isInserted(),
    hasUpdateAvailable: _hasUpdateAvailable(),
    allowReinsert:
      config.connectAllowReinsertVersion < new Date(controllerVersion ?? new Date("1970.1.1")) &&
      connectPlatform === "MRV",
    subscriptionStatus: subscriptionStatus,
    onPermalinkUsed: sendPermalinkUsedTelemetryEvent,
    onDiagramClicked: sendDiagramClickedTelemetryEvent,
  };

  return productDetailsNotFound ? (
    <Navigate to={`${getBasePath()}/404`} />
  ) : isOpen ? (
    <Dialog onClose={onProductRequestClose} open={isOpen} className="ProductDetailsPopup" fullWidth maxWidth={false}>
      <ProductDetails {...productDetailsProps} />
      <ProductRequestContainer open={productRequestOpen} handleClose={onProductRequestClose} />
      <SignupDialogContainer open={signupDialogOpen} handleClose={onSignupDialogClose} />
      {snackbar}
    </Dialog>
  ) : (
    <Fragment>
      <ProductDetails {...productDetailsProps} />
      <ProductRequestContainer open={productRequestOpen} handleClose={onProductRequestClose} />
      <SignupDialogContainer open={signupDialogOpen} handleClose={onSignupDialogClose} />
      {snackbar}
    </Fragment>
  );
};
