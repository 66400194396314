export const connectParamsKeys: (keyof ConnectParams)[] = [
  "useridhash",
  "featurekey",
  "app",
  "appver",
  "discipline",
  "disciplines",
  "class",
  "classes",
  "area",
  "lang",
  "pluginver",
  "ctrlver",
  "platform",
  "ventilationunits",
  "pipingunits",
  "radiatorunits",
  "manufacturer",
  "token",
  "hasopdata",
  "opname",
  "hasdatasetinfo",
  "switchedfrom",
  "connectsettings",
];

export const requiredConnectParams = ["app", "appver", "area", "lang", "pluginver", "platform"];

export interface ConnectParams {
  useridhash?: string | null;
  featurekey?: string | null;
  app: ConnectApp;
  appver: string;
  discipline?: number;
  disciplines?: number[];
  class?: number;
  classes?: number[];
  area: string;
  lang: string;
  pluginver: string;
  ctrlver?: string;
  platform: string;
  ventilationunits?: ConnectUnits;
  pipingunits?: ConnectUnits;
  radiatorunits?: ConnectRadiatorUnits;
  manufacturer?: string;
  token?: string;
  hasopdata?: boolean;
  opname?: string;
  hasdatasetinfo?: boolean;
  switchedfrom?: ConnectOperationSwitchInfo;
  connectsettings?: Connectsettings;
}

export interface ConnectOperationSwitchInfo {
  ModuleKey: string;
  FeatureKey: string;
}

export interface ConnectApp {
  id: string;
  platform: string;
  platforms?: string[];
  version: number;
}

export interface ConnectRadiatorUnits {
  mass?: number;
  power?: number;
  temp?: number;
  vol?: number;
}

export interface ConnectUnits {
  qv?: number;
  dp?: number;
  ve?: number;
}

export interface Connectsettings {
  UpdateableChoice?: 1 | 0;
}

export const defaultPlainRevitParams: ConnectParams = {
  useridhash: "20980606795374f7624d279d9d8e9ce9b5a2715db9695e3d80c275dc234dbad3",
  featurekey: "MagiCloudSearch",
  app: {
    id: "revit",
    platform: "CRV",
    platforms: ["CRV"],
    version: 220,
  },
  appver: "CRV220",
  area: "",
  lang: "en-US",
  pluginver: "2022.1.4",
  ctrlver: "2022.1.4",
  platform: "revit_2022",
  ventilationunits: {
    qv: 26,
    dp: 42,
  },
  pipingunits: {
    qv: 22,
    dp: 43,
  },
  radiatorunits: {
    power: 52,
  },
  hasopdata: false,
  hasdatasetinfo: false,
};

export const defaultMagiCadParams: ConnectParams = {
  useridhash: "20980606795374f7624d279d9d8e9ce9b5a2715db9695e3d80c275dc234dbad3",
  app: {
    id: "magicad",
    platform: "MRV",
    version: 192,
  },
  appver: "MRV192",
  area: "FIN",
  lang: "en-GB",
  pluginver: "2017.3.2",
  platform: "revit_2019",
};

export const isConnectParams = (data: unknown): data is ConnectParams => {
  if (!data || typeof data !== "object") throw new Error();
  return hasProperties(data, requiredConnectParams);
};

const hasProperties = (obj: unknown, properties: string[]) => {
  return properties.every((property) => Object.prototype.hasOwnProperty.call(obj, property));
};
