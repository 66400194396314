import { User } from "oidc-client-ts";
import { authConfig } from "../config/auth";

export const getToken = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${authConfig.authority as string}:${authConfig.client_id as string}`
  );
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);

  return user.access_token;
};

export const isAuthenticated = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${authConfig.authority as string}:${authConfig.client_id as string}`
  );
  if (!oidcStorage) {
    return false;
  }

  const user = User.fromStorageString(oidcStorage);

  return !!user.access_token && !user.expired;
};
