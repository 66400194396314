import { createSlice } from "@reduxjs/toolkit";
import { Manufacturer } from "@magicad-cloud/component-library";
import { searchApi } from "../api/searchApi";

const initialState: Manufacturer = {
  Id: "",
  Name: "",
  LogoUrl: "",
  IconUrl: null,
  ContentAccess: [],
  Visible: false,
  TrialDownloads: null,
  UrlFriendlyName: "",
};

const manufacturerSlice = createSlice({
  name: "manufacturer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(searchApi.endpoints.getManufacturer.matchFulfilled, (_, { payload: manufacturer }) => {
      document.title = `MagiCAD Cloud | ${manufacturer.Name}`;
      return manufacturer;
    });
  },
});

export default manufacturerSlice.reducer;
