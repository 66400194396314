import { Fragment, useState } from "react";
import { PrivacyPolicyFooter, ProductRequestNotification } from "@magicad-cloud/component-library";
import { AppBarContainer } from "../../components/appBarContainer/AppBarContainer";
import { Banners } from "../../components/banners/Banners";
import { useAuth } from "react-oidc-context";
import { ProductRequestContainer } from "../../components/productRequestContainer/ProductRequestContainer";
import { SignupDialogContainer } from "../../components/signupDialogContainer/SignupDialogContainer";
import { NewVersionAlert } from "../../components/newVersionAlert/NewVersionAlert";
import {
  ProductNotFoundLayout,
  ProductNotFoundLayoutProps,
} from "../../layouts/productNotFoundLayout/ProductNotFoundLayout";

export const ProductNotFoundPage = () => {
  const auth = useAuth();

  const [productRequestOpen, setProductRequestOpen] = useState(false);
  const [signupDialogOpen, setSignupDialogOpen] = useState(false);

  const handleProductRequestOpen = () => {
    auth.isAuthenticated ? setProductRequestOpen(true) : setSignupDialogOpen(true);
  };

  const newVersionAlert = <NewVersionAlert />;
  const appBar = <AppBarContainer handleProductRequestOpen={handleProductRequestOpen} />;
  const banner = <Banners />;
  const productRequestNotification = <ProductRequestNotification handleOpen={handleProductRequestOpen} />;
  const footer = <PrivacyPolicyFooter />;

  const productNotFoundLayoutProps: ProductNotFoundLayoutProps = {
    newVersionAlert,
    appBar,
    banner,
    productRequestNotification,
    footer,
  };

  return (
    <Fragment>
      <ProductNotFoundLayout {...productNotFoundLayoutProps} />
      <ProductRequestContainer open={productRequestOpen} handleClose={() => setProductRequestOpen(false)} />
      <SignupDialogContainer open={signupDialogOpen} handleClose={() => setSignupDialogOpen(false)} />
    </Fragment>
  );
};
