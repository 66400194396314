import { isManufacturerSubdomain } from "./../helpers/urlHelpers";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { replaceSearchPath, getSubdomain } from "../helpers/urlHelpers";

export const useUrlShortener = () => {
  const { manufacturerNameOrId: manufacturerNameOrIdParam, encodedParams } = useParams();
  const subdomain = getSubdomain();

  useEffect(() => {
    if (manufacturerNameOrIdParam) {
      const path = window.location.pathname;

      if (path.includes("/home/")) {
        const shortPath = path.replace(`/home/${manufacturerNameOrIdParam}`, "");

        if (encodedParams) {
          replaceSearchPath(encodedParams, manufacturerNameOrIdParam);
        } else if (subdomain && isManufacturerSubdomain(subdomain)) {
          window.history.replaceState({}, "", shortPath);
        }
      }
    }
  }, [encodedParams, manufacturerNameOrIdParam, subdomain]);
};
