import { useCallback, useEffect, useMemo, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import TelemetryContext from "../telemetryContext";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "react-oidc-context";
import hash from "hash.js";
import { ApplicationParams } from "../../slices/applicationParamsSlice";

export interface TelemetryProviderProps {
  applicationParams: ApplicationParams;
  children: React.ReactNode;
}

export const TelemetryProvider = ({ applicationParams, children }: TelemetryProviderProps) => {
  const [sessionId] = useState<string>(uuidv4());
  const [browserFingerprint, setBrowserFingerprint] = useState<string>("");
  const [telemetryReady, setTelemetryReady] = useState(false);
  const auth = useAuth();

  const fpPromise = FingerprintJS.load();

  useEffect(() => {
    fpPromise
      .then((agent) => agent.get())
      .then((result) => result.visitorId)
      .then((fingerprint) => setBrowserFingerprint(fingerprint))
      .then(() => setTelemetryReady(true))
      .catch(console.error);
  }, [fpPromise]);

  const getCommonTelemetryProperties = useCallback(
    () => ({
      BrowserFingerprint: browserFingerprint,
      SessionId: sessionId,
      ApplicationType: applicationParams.ApplicationType,
      ApplicationPlatform: applicationParams.ApplicationPlatform,
      ConnectPlatform: applicationParams.ConnectPlatform,
      MagicadUser: applicationParams.MagicadUser,
      RegisteredUser: auth.user?.profile?.email ? hash.sha256().update(auth.user.profile.email).digest("hex") : null,
    }),
    [applicationParams, auth, browserFingerprint, sessionId]
  );

  const contextValue = useMemo(() => {
    return {
      getCommonTelemetryProperties,
      telemetryReady,
    };
  }, [getCommonTelemetryProperties, telemetryReady]);

  return <TelemetryContext.Provider value={contextValue}>{children}</TelemetryContext.Provider>;
};
