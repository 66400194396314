import {
  AttenuationFilter,
  AttenuationMenu,
  AttenuationValues,
  createAttenuationFilterChip,
} from "@magicad-cloud/component-library";
import { useAppDispatch, useAppSelector, useCurrentSearchOptionsResult, useCurrentSearchResults } from "../../../hooks";
import { addOperativeCustomFilterChip } from "../../../slices/chipsSlice";
import { addOperativeFilter as addOperativeFilterToSearchOptionsRequest } from "../../../slices/searchOptionsRequestSlice";
import {
  addOperativeFilter as addOperativeFilterToSearchRequest,
  attenuationFilterSelector,
} from "../../../slices/searchRequestSlice";

export const AttenuationFilterContainer = () => {
  const dispatch = useAppDispatch();
  const { searchOptionsResultLoading } = useCurrentSearchOptionsResult();
  const { searchResultsLoading } = useCurrentSearchResults();

  const activeAttenuationFilter = useAppSelector((state) => attenuationFilterSelector(state));

  const onSubmit = (data: AttenuationValues) => {
    const attenuationFilter: AttenuationFilter = {
      Name: "Attenuation",
      Values: data,
    };

    dispatch(addOperativeCustomFilterChip(createAttenuationFilterChip(attenuationFilter)));
    dispatch(addOperativeFilterToSearchOptionsRequest(attenuationFilter));
    dispatch(addOperativeFilterToSearchRequest(attenuationFilter));
  };

  return (
    <AttenuationMenu
      values={activeAttenuationFilter?.Values as AttenuationValues}
      disabled={searchOptionsResultLoading || searchResultsLoading}
      onSubmit={onSubmit}
    />
  );
};
