import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { config } from "../config/config";
import { useAppSelector } from "./reduxHooks";
import { getSubdomain } from "../helpers/urlHelpers";

const getVersion = async (manufacturerName: string): Promise<string> => {
  if (config.environment === "local") {
    const hasSubdomain = getSubdomain();

    const localBaseUrl = hasSubdomain ? `https://${manufacturerName}.localhost:3000` : "https://localhost:3000";

    return (await fetch(localBaseUrl + "/localVersion.txt", { cache: "reload" })).text();
  }

  return (await fetch("/version.txt", { cache: "reload" })).text();
};

const useVersionComparer = (): boolean => {
  const [currentVersion, setCurrentVersion] = useState<string | undefined>();
  const [isLatestVersion, setIsLatestVersion] = useState<boolean>(true);
  const manufacturerName = useAppSelector((store) => store.manufacturer.Name);

  useEffect(() => {
    setInterval(() => {
      async function compareVersions() {
        const latestVersion = await getVersion(manufacturerName);

        if (currentVersion && currentVersion !== latestVersion) {
          setIsLatestVersion(false);
        }

        setCurrentVersion(latestVersion);
      }

      void compareVersions();
    }, config.versionCheckInterval);
  }, [currentVersion, manufacturerName]);

  return isLatestVersion;
};

export const useVersionAlert = (): {
  versionInfoAlertOpen: boolean;
  setVersionInfoAlertOpen: Dispatch<SetStateAction<boolean>>;
} => {
  const [versionInfoAlertOpen, setVersionInfoAlertOpen] = useState(false);
  const isLatestVersion = useVersionComparer();

  useEffect(() => {
    !isLatestVersion ? setVersionInfoAlertOpen(true) : setVersionInfoAlertOpen(false);
  }, [isLatestVersion]);

  return { versionInfoAlertOpen, setVersionInfoAlertOpen };
};
