import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { config } from "../config/config";

void i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${config.translationsUrl}/locale-{{lng}}.json?${config.releaseNumber}branded`,
      crossDomain: true,
    },
    supportedLngs: config.supportedLanguages,
  });

export default i18n;
