import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectParams } from "../models/connectParams";

export interface ApplicationParams {
  ApplicationType: "magicad-branded" | "revit-branded" | "web-branded"; // connectParams.app.id
  MagicadUser: string | null; // connectParams.useridhash
  ApplicationPlatform: string | null; // connectParams.platform
  ConnectPlatform: string | null; // connectParams.app.platform
  ControllerVersion: string | null; // connectParams.ctrlver
}

const initialState: ApplicationParams = {
  ApplicationType: "web-branded",
  MagicadUser: null,
  ApplicationPlatform: null,
  ConnectPlatform: null,
  ControllerVersion: null,
};

const applicationSlice = createSlice({
  name: "applicationParams",
  initialState,
  reducers: {
    initApplicationParams(state, { payload: connectParams }: PayloadAction<ConnectParams>) {
      state.ApplicationType = connectParams.app.id === "magicad" ? "magicad-branded" : "revit-branded";
      state.ApplicationPlatform = connectParams.platform;
      state.ConnectPlatform = connectParams.app.platform;
      state.MagicadUser = connectParams.useridhash ?? null;
      state.ControllerVersion = connectParams.ctrlver ?? null;
    },
  },
});

export const { initApplicationParams } = applicationSlice.actions;

export default applicationSlice.reducer;
