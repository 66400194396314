import { Fragment, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Navigate, useParams } from "react-router-dom";
import { AppBarContainer } from "../../components/appBarContainer/AppBarContainer";
import { NewVersionAlert } from "../../components/newVersionAlert/NewVersionAlert";
import { ProductDetailsView } from "../../components/productDetailsView/ProductDetailsView";
import { ProductRequestContainer } from "../../components/productRequestContainer/ProductRequestContainer";
import { SignupDialogContainer } from "../../components/signupDialogContainer/SignupDialogContainer";
import { getBasePath } from "../../helpers/urlHelpers";
import { useUrlShortener } from "../../hooks";
import {
  ProductDetailsLayout,
  ProductDetailsLayoutProps,
} from "../../layouts/productDetailsLayout/ProductDetailsLayout";

export const ProductDetailsPage = () => {
  useUrlShortener();
  const { productId } = useParams();
  const [productRequestOpen, setProductRequestOpen] = useState(false);
  const [signupDialogOpen, setSignupDialogOpen] = useState(false);
  const auth = useAuth();

  const handleProductRequestOpen = () => {
    auth.isAuthenticated ? setProductRequestOpen(true) : setSignupDialogOpen(true);
  };

  const newVersionAlert = <NewVersionAlert />;
  const appBar = <AppBarContainer handleProductRequestOpen={handleProductRequestOpen} />;

  const productDetailsView = productId ? (
    <ProductDetailsView
      productId={productId}
      productRequestOpen={productRequestOpen}
      handleProductRequestOpen={handleProductRequestOpen}
      onProductRequestClose={() => setProductRequestOpen(false)}
      signupDialogOpen={signupDialogOpen}
      handleSignupDialogOpen={() => setSignupDialogOpen(true)}
      onSignupDialogClose={() => setSignupDialogOpen(false)}
    />
  ) : (
    <Navigate to={`${getBasePath()}/404`} />
  );

  const productDetailsLayoutProps: ProductDetailsLayoutProps = {
    newVersionAlert: newVersionAlert,
    appBar: appBar,
    productDetailsView: productDetailsView,
  };

  return (
    <Fragment>
      <ProductDetailsLayout {...productDetailsLayoutProps} />
      <ProductRequestContainer open={productRequestOpen} handleClose={() => setProductRequestOpen(false)} />
      <SignupDialogContainer open={signupDialogOpen} handleClose={() => setSignupDialogOpen(false)} />
    </Fragment>
  );
};
