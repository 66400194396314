import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Router from "./app/Router";
import { ThemeProvider } from "@mui/material";
import { SpinningLogo } from "./components/spinningLogo/SpinningLogo";
import { magicadCloudTheme } from "@magicad-cloud/component-library";
import "./i18n/i18n";
import "./index.scss";

const container = document.getElementById("app");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={magicadCloudTheme}>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<SpinningLogo />}>
            <Router />
          </Suspense>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
