import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BannerValues } from "@magicad-cloud/component-library";
import { BannerSearchRequest } from "../common/types";
import { config } from "../config/config";

export const configurationApi = createApi({
  reducerPath: "configurationApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${config.configurationUrl}/` }),
  endpoints: (builder) => ({
    getBannersByLocaleAndGuid: builder.query<BannerValues[], BannerSearchRequest>({
      query: (searchRequest: BannerSearchRequest) => ({
        url: "banners",
        params: searchRequest,
      }),
    }),
  }),
});

export const { useGetBannersByLocaleAndGuidQuery } = configurationApi;
