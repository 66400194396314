import { useSigninRedirect } from "./authHooks";
import { useEffect, useRef } from "react";
import { useAuth } from "react-oidc-context";
import { useAppSelector } from "./reduxHooks";

export const useForcedLogin = () => {
  const applicationParams = useAppSelector((store) => store.applicationParams);
  const auth = useAuth();
  const loginForcedContainer = useRef(false);
  const handleLogin = useSigninRedirect();

  useEffect(() => {
    if (!loginForcedContainer.current && applicationParams.ApplicationType === "revit-branded") {
      if (!auth.isLoading) {
        loginForcedContainer.current = true;
        if (!auth.isAuthenticated) handleLogin();
      }
    }
  }, [auth, applicationParams.ApplicationType, handleLogin]);
};
