import { useCallback, useMemo } from "react";
import {
  GenericRangeFacet,
  GenericRangeAggregate,
  AggregateFilter,
  OperativeRangeFilter,
  getOperativeRangeFilterName,
  createOperativeRangeFilterChip,
} from "@magicad-cloud/component-library";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { FacetSkeletons } from "../facetSkeletons/FacetSkeletons";
import {
  addOperativeFilter as addOperativeFilterToSearchOptionsRequest,
  removeOperativeFilter as removeOperativeFilterFromSearchOptionsRequest,
} from "../../../../slices/searchOptionsRequestSlice";
import {
  addOperativeFilter as addOperativeFilterToSearchRequest,
  removeOperativeFilter as removeOperativeFilterFromSearchRequest,
} from "../../../../slices/searchRequestSlice";
import { addChip, removeChip } from "../../../../slices/chipsSlice";

interface GenericRangeFacetGroupProps {
  aggregates: GenericRangeAggregate[];
  loading: boolean;
  addAggregateFilter: (aggregateFilter: AggregateFilter) => void;
  removeAggregateFilter: (aggregateFilter: AggregateFilter) => void;
}

export const GenericRangeFacetGroup = ({
  aggregates,
  loading,
  addAggregateFilter,
  removeAggregateFilter,
}: GenericRangeFacetGroupProps) => {
  const dispatch = useAppDispatch();
  const operativeFilters = useAppSelector((store) => store.searchRequest.searchRequest.OperativeFilters);

  const selectOperativeFilterByName = useCallback(
    (name: string) => operativeFilters.find((f) => f.Name === name),
    [operativeFilters]
  );

  const genericRangeFacets = useMemo(() => {
    const handleOperativeFilterAdd = (operativeFilter: OperativeRangeFilter) => {
      dispatch(addChip(createOperativeRangeFilterChip(operativeFilter)));
      dispatch(addOperativeFilterToSearchOptionsRequest(operativeFilter));
      dispatch(addOperativeFilterToSearchRequest(operativeFilter));
    };

    const handleOperativeFilterRemove = (operativeFilter: OperativeRangeFilter) => {
      dispatch(removeChip(createOperativeRangeFilterChip(operativeFilter)));
      dispatch(removeOperativeFilterFromSearchOptionsRequest(operativeFilter));
      dispatch(removeOperativeFilterFromSearchRequest(operativeFilter));
    };

    return (
      <>
        {aggregates.map((aggregate) => (
          <GenericRangeFacet
            key={aggregate.Name}
            aggregate={aggregate}
            activeOperativeFilter={
              selectOperativeFilterByName(getOperativeRangeFilterName(aggregate.Name)) as OperativeRangeFilter
            }
            units="mm"
            addAggregateFilter={addAggregateFilter}
            removeAggregateFilter={removeAggregateFilter}
            addOperativeFilter={handleOperativeFilterAdd}
            removeOperativeFilter={handleOperativeFilterRemove}
          />
        ))}
      </>
    );
  }, [addAggregateFilter, aggregates, dispatch, removeAggregateFilter, selectOperativeFilterByName]);

  return loading ? <FacetSkeletons amount={aggregates.length} /> : genericRangeFacets;
};
