import { SignupDialog } from "@magicad-cloud/component-library";
import { config } from "../../config/config";
import { useSigninRedirect } from "../../hooks";

interface SignupDialogContainerProps {
  open: boolean;
  handleClose: () => void;
}

export const SignupDialogContainer = ({ open, handleClose }: SignupDialogContainerProps) => {
  const handleLogin = useSigninRedirect();

  return (
    <SignupDialog
      open={open}
      illustrationUrl={config.brandedIllustrationUrl}
      headerText="UI.SignupDialog.BrandedPage.Header"
      interval={4000}
      marketingPoints={[
        "UI.SignupDialog.BrandedPage.MarketingText.1",
        "UI.SignupDialog.BrandedPage.MarketingText.2",
        "UI.SignupDialog.BrandedPage.MarketingText.3",
      ]}
      handleClose={handleClose}
      handleLogin={handleLogin}
      signupUrl={config.signupUrl}
    />
  );
};
