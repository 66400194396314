import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

const parseBoolEnvValue = (name: string, value: string | undefined) => {
  if (value !== "0" && value !== "1" && value !== undefined) {
    throw new Error(`${name} invalid`);
  }

  return value === "1";
};

const parseStorageEnvValue = (name: string, value: string | undefined) => {
  if (value !== "localStorage" && value !== "sessionStorage" && value !== undefined) {
    throw new Error(`${name} invalid`);
  }

  return value;
};

const authority = process.env.REACT_APP_AUTH_AUTHORITY;
const client_id = process.env.REACT_APP_AUTH_CLIENT_ID;
const loadUserInfo = parseBoolEnvValue("REACT_APP_AUTH_LOADUSERINFO", process.env.REACT_APP_AUTH_LOADUSERINFO);
const post_logout_redirect_uri = process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI;
const redirect_uri = process.env.REACT_APP_AUTH_REDIRECT_URI;
const revokeTokensOnSignout = parseBoolEnvValue(
  "REACT_APP_AUTH_REVOKEACCESSTOKENONSIGNOUT",
  process.env.REACT_APP_AUTH_REVOKEACCESSTOKENONSIGNOUT
);
const scope = process.env.REACT_APP_AUTH_SCOPE;
const userStore = parseStorageEnvValue("REACT_APP_AUTH_USERSTORE", process.env.REACT_APP_AUTH_USERSTORE);

if (!authority) throw new Error("REACT_APP_AUTH_AUTHORITY not defined");
if (!client_id) throw new Error("REACT_APP_AUTH_CLIENTID not defined");
if (!post_logout_redirect_uri) throw new Error("REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI not defined");
if (!redirect_uri) throw new Error("REACT_APP_AUTH_REDIRECT_URI not defined");
if (!scope) throw new Error("REACT_APP_AUTH_SCOPE not defined");

const baseUrl = window.location.protocol + "//" + window.location.host;

export const authConfig: AuthProviderProps = {
  authority,
  client_id,
  loadUserInfo: loadUserInfo,
  post_logout_redirect_uri: `${baseUrl}${post_logout_redirect_uri}`,
  redirect_uri: `${baseUrl}${redirect_uri}`,
  revokeTokensOnSignout: revokeTokensOnSignout,
  scope,
  userStore: userStore ? new WebStorageStateStore({ store: window[userStore] }) : undefined,
  onSigninCallback: () => window.location.replace(localStorage.getItem("magicadcloud.branded.path") as string),
};
