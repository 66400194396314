export class ManufacturerNotProvidedError extends Error {
  constructor(message = "Manufacturer not provided") {
    super(message);
    this.name = "ManufacturerNotProvidedError";
  }
}

export class ManufacturerNotFoundError extends Error {
  constructor(message = "Manufacturer not found") {
    super(message);
    this.name = "ManufacturerNotFoundError";
  }
}

export class ProductNotFoundError extends Error {
  constructor(message = "Product not found") {
    super(message);
    this.name = "ProductNotFoundError";
  }
}

export class UnexpectedError extends Error {
  constructor(message = "Unexpected error occured, please try again later") {
    super(message);
    this.name = "UnexpectedError";
  }
}

export class InvalidBase64Error extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "InvalidBase64Error";
  }
}
