import { InfoAlert } from "@magicad-cloud/component-library";
import { useVersionAlert } from "../../hooks";

export const NewVersionAlert = () => {
  const { versionInfoAlertOpen, setVersionInfoAlertOpen } = useVersionAlert();

  return (
    <InfoAlert
      alertOpen={versionInfoAlertOpen}
      setAlertOpen={(open) => setVersionInfoAlertOpen(open)}
      message="UI.Alert.Info.Update"
      buttonLabel="UI.Button.Update"
      closable={false}
      onClick={() => window.location.reload()}
    />
  );
};
