import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectParams } from "../models/connectParams";
import { RootState } from "../app/store";

export type ConnectParamsState = ConnectParams | null;

const connectParamsSlice = createSlice({
  name: "connectParams",
  initialState: null as ConnectParamsState,
  reducers: {
    setConnectParams(_, action: PayloadAction<ConnectParams>) {
      return action.payload;
    },
  },
});

export const { setConnectParams } = connectParamsSlice.actions;

export const selectUpdateableChoice = (state: RootState): 1 | 0 | undefined =>
  state.connectParams?.connectsettings?.UpdateableChoice;

export default connectParamsSlice.reducer;
