import {
  createDraftSafeSelector,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FilterChipEntity } from "@magicad-cloud/component-library";

const chipsAdapter = createEntityAdapter<FilterChipEntity>({
  selectId: (chipData) => chipData.id,
});

const chipsSlice = createSlice({
  name: "chips",
  initialState: chipsAdapter.getInitialState(),
  reducers: {
    addChip(state, action: PayloadAction<FilterChipEntity>) {
      chipsAdapter.addOne(state, action.payload);
    },
    addOperativeCustomFilterChip(state, action: PayloadAction<FilterChipEntity>) {
      chipsAdapter.upsertOne(state, action.payload);
    },
    removeChip(state, action: PayloadAction<FilterChipEntity>) {
      chipsAdapter.removeOne(state, action.payload.id);
    },
    removeChipById(state, action: PayloadAction<string>) {
      chipsAdapter.removeOne(state, action.payload);
    },
    clearAllFacetChips(state) {
      const aggregateFilterChipIds = chipDraftSelector.selectAggregateFilterChipIds(state);
      const operativeRangeFilterChipIds = chipDraftSelector.selectOperativeRangeFilterChipIds(state);
      const allFacetChipIds = aggregateFilterChipIds.concat(operativeRangeFilterChipIds);
      chipsAdapter.removeMany(state, allFacetChipIds);
    },
    clearAllChips: () => chipsAdapter.getInitialState(),
  },
});

export const chipsSelectors = chipsAdapter.getSelectors<EntityState<FilterChipEntity>>((state) => state);

const selectChipsState = (state: EntityState<FilterChipEntity>) => state;

export const chipsSelector = {
  selectProductClassChips: createSelector(selectChipsState, (state) => {
    const allChips = chipsSelectors.selectAll(state);

    const productClassChips = allChips.filter((c) => c.type === "PRODUCTCLASSFILTER");

    return productClassChips;
  }),
};

const chipDraftSelector = {
  selectAggregateFilterChipIds: createDraftSafeSelector(selectChipsState, (state) => {
    const allChips = chipsSelectors.selectAll(state);

    const aggregateFilterChips = allChips.filter((c) => c.type === "AGGREGATEFILTER");

    const aggregateFilterChipIds = aggregateFilterChips.map((c) => c.id);

    return aggregateFilterChipIds;
  }),
  selectOperativeRangeFilterChipIds: createDraftSafeSelector(selectChipsState, (state) => {
    const allChips = chipsSelectors.selectAll(state);

    const operativeFilterChips = allChips.filter((c) => c.type === "OPERATIVERANGEFILTER");

    const operativeFilterChipIds = operativeFilterChips.map((c) => c.id);

    return operativeFilterChipIds;
  }),
};

export const { addChip, addOperativeCustomFilterChip, removeChip, removeChipById, clearAllFacetChips, clearAllChips } =
  chipsSlice.actions;

export default chipsSlice.reducer;
