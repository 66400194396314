import { Chip } from "@mui/material";
import { ClearFiltersChip, FilterChip, FilterChipEntity } from "@magicad-cloud/component-library";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { chipsSelectors, clearAllChips, removeChip } from "../../slices/chipsSlice";
import { useTranslation } from "react-i18next";
import {
  removeAggregateFilter as removeAggregateFilterFromSearchOptionsRequest,
  removeAttachmentFilter as removeAttachmentFilterFromSearchOptionsRequest,
  removeOperativeFilter as removeOperativeFilterFromSearchOptionsRequest,
  removeProductClassId,
} from "../../slices/searchOptionsRequestSlice";
import {
  clearRequestFilters,
  removeAggregateFilter as removeAggregateFilterFromSearchRequest,
  removeAttachmentFilter as removeAttachmentFilterFromSearchRequest,
  removeOperativeFilter as removeOperativeFilterFromSearchRequest,
  removeProductClass,
  removeTextFilter,
} from "../../slices/searchRequestSlice";
import "./FilterChips.scss";

interface FilterChipsProps {
  handleFacetDrawerOpenWithProductGroupsExpanded: () => void;
}

export const FilterChips = ({ handleFacetDrawerOpenWithProductGroupsExpanded }: FilterChipsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const chipEntities = useAppSelector((state) => chipsSelectors.selectAll(state.chips));
  const productGroupFilters = useAppSelector((state) => state.searchRequest.searchRequest.ProductGroups) ?? [];

  const onDelete = (chip: FilterChipEntity) => {
    dispatch(removeChip(chip));
    removeRequestFiltersOnChipDelete(chip);
  };

  const removeRequestFiltersOnChipDelete = (chip: FilterChipEntity) => {
    switch (chip.type) {
      case "AGGREGATEFILTER":
        dispatch(removeAggregateFilterFromSearchOptionsRequest(chip.searchRequestPayload));
        dispatch(removeAggregateFilterFromSearchRequest(chip.searchRequestPayload));
        break;
      case "ATTACHMENTFILTER":
        dispatch(removeAttachmentFilterFromSearchOptionsRequest(chip.searchRequestPayload));
        dispatch(removeAttachmentFilterFromSearchRequest(chip.searchRequestPayload));
        break;
      case "OPERATIVECUSTOMFILTER":
        dispatch(removeOperativeFilterFromSearchOptionsRequest(chip.searchRequestPayload));
        dispatch(removeOperativeFilterFromSearchRequest(chip.searchRequestPayload));
        break;
      case "OPERATIVERANGEFILTER":
        dispatch(removeOperativeFilterFromSearchOptionsRequest(chip.searchRequestPayload));
        dispatch(removeOperativeFilterFromSearchRequest(chip.searchRequestPayload));
        break;
      case "PRODUCTCLASSFILTER":
        dispatch(removeProductClassId(chip.searchRequestPayload));
        dispatch(removeProductClass(chip.searchRequestPayload));
        break;
      case "TEXTFILTER":
        dispatch(removeTextFilter(chip.searchRequestPayload));
        break;
      default:
        break;
    }
  };

  const onClearChips = () => {
    dispatch(clearAllChips());
    dispatch(clearRequestFilters());
  };

  const showChips = chipEntities.length > 0 || (productGroupFilters && productGroupFilters.length > 0);

  return showChips ? (
    <div className="FilterChips">
      {productGroupFilters.length > 0 ? (
        <div>
          <Chip
            color="primary"
            size="medium"
            onClick={handleFacetDrawerOpenWithProductGroupsExpanded}
            label={t("UI.Chip.ProductGroupsSelected")}
            onDelete={onClearChips}
          />
        </div>
      ) : null}
      {chipEntities.map((chipData) => (
        <div key={chipData.id}>
          <FilterChip chipData={chipData} color="primary" variant="outlined" size="medium" onDelete={onDelete} />
        </div>
      ))}
      {chipEntities.length > 0 ? (
        <div>
          <ClearFiltersChip size="medium" onClearChips={onClearChips} />
        </div>
      ) : null}
    </div>
  ) : null;
};
