import {
  ProductTypeMenu,
  BimMenu,
  MarketAreaMenu,
  ManufacturerCollectionsMenu,
  ProductClass,
  AttachmentType,
  ManufacturerCollection,
  createAttachmentChip,
  createProductClassChip,
} from "@magicad-cloud/component-library";
import { useAppDispatch, useAppSelector, useCurrentSearchOptionsResult, useCurrentSearchResults } from "../../../hooks";
import {
  addAttachmentFilter as addAttachmentFilterToSearchOptionsRequest,
  addProductClassId,
  setSearchOptionsProductSetId,
  setSearchOptionsRequestMarketAreas,
} from "../../../slices/searchOptionsRequestSlice";
import {
  addAttachmentFilter as addAttachmentFilterToSearchRequest,
  addProductClass,
  setProductSetId,
  setSearchRequestMarketAreas,
} from "../../../slices/searchRequestSlice";
import { useAuth } from "react-oidc-context";
import { useGetManufacturerCollectionsQuery } from "../../../api/collectionsApi";
import { addChip } from "../../../slices/chipsSlice";
import "./SearchFilters.scss";

interface SearchFiltersProps {
  setSignupDialogOpen: () => void;
}

const SearchFilters = ({ setSignupDialogOpen }: SearchFiltersProps) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();

  const manufacturer = useAppSelector((store) => store.manufacturer);

  const selectedAttachmentIds = useAppSelector((state) => state.searchRequest.searchRequest.AttachmentTypes);
  const selectedMarketArea = useAppSelector((state) => state.searchRequest.searchRequest.MarketAreas)[0] ?? undefined;
  const selectedCollectionId = useAppSelector((state) => state.searchRequest.searchRequest.ProductSetId) ?? undefined;

  const { searchOptionsResult: searchOptions, searchOptionsResultLoading } = useCurrentSearchOptionsResult();
  const { searchResultsLoading } = useCurrentSearchResults();

  const loading = searchOptionsResultLoading || searchResultsLoading;

  const { data: manufacturerCollections, isSuccess: manufacturerCollectionsLoaded } =
    useGetManufacturerCollectionsQuery(manufacturer.Id, { pollingInterval: 60000 });

  const handleProductClassClick = (productClass: ProductClass) => {
    dispatch(addChip(createProductClassChip(productClass.IdentifyingName, productClass.Id)));
    dispatch(addProductClassId(productClass.Id));
    dispatch(addProductClass(productClass.Id));
  };

  const handleBimOptionClick = (attachmentType: AttachmentType) => {
    dispatch(addChip(createAttachmentChip(attachmentType.Name, attachmentType.Id)));
    dispatch(addAttachmentFilterToSearchOptionsRequest(attachmentType.Id));
    dispatch(addAttachmentFilterToSearchRequest(attachmentType.Id));
  };

  const handleMarketAreaClick = (id: string) => {
    dispatch(setSearchOptionsRequestMarketAreas(id));
    dispatch(setSearchRequestMarketAreas(id));
  };

  const handleManufacturerCollectionsClick = (manufacturerCollection: ManufacturerCollection | null) => {
    if (auth.isAuthenticated) {
      dispatch(setSearchOptionsProductSetId(manufacturerCollection?.CollectionId ?? null));
      dispatch(setProductSetId(manufacturerCollection?.CollectionId ?? null));
    } else {
      setSignupDialogOpen();
    }
  };

  return (
    <div className="SearchFilters">
      <ProductTypeMenu
        productClasses={searchOptions?.ProductClasses ?? []}
        disabled={loading}
        onMenuItemClick={handleProductClassClick}
      />
      <BimMenu
        attachmentTypes={searchOptions?.AttachmentTypes ?? []}
        selectedAttachmentIds={selectedAttachmentIds}
        disabled={loading}
        onMenuItemClick={handleBimOptionClick}
      />
      <MarketAreaMenu
        marketAreas={searchOptions?.MarketAreas ?? []}
        disabled={loading}
        onMenuItemClick={handleMarketAreaClick}
        selectedMarketAreaId={selectedMarketArea}
      />
      <ManufacturerCollectionsMenu
        manufacturerCollections={manufacturerCollections ?? []}
        disabled={loading || !manufacturerCollectionsLoaded}
        isUserSignedIn={auth.isAuthenticated}
        onMenuItemClick={handleManufacturerCollectionsClick}
        selectedCollectionId={selectedCollectionId}
      />
    </div>
  );
};

export default SearchFilters;
