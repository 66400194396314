import { ManufacturerNotProvidedError } from "./../models/errors";
import { useParams } from "react-router-dom";
import { getSubdomain, isManufacturerSubdomain } from "../helpers/urlHelpers";

export const useManufacturerNameOrIdReader = (): string => {
  const { manufacturerNameOrId: manufacturerNameOrIdParam } = useParams();

  const subdomain = getSubdomain();

  if (subdomain && isManufacturerSubdomain(subdomain)) {
    localStorage.setItem("magicadcloud.branded.manufacturer", subdomain);
    return subdomain;
  }

  if (manufacturerNameOrIdParam) {
    localStorage.setItem("magicadcloud.branded.manufacturer", manufacturerNameOrIdParam);
    return manufacturerNameOrIdParam;
  }

  throw new ManufacturerNotProvidedError();
};
