import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { setSearchOptionsProductSetId } from "../slices/searchOptionsRequestSlice";
import { setProductSetId } from "../slices/searchRequestSlice";
import { useSnackbar } from "notistack";
import { Button } from "@magicad-cloud/component-library";
import {
  useFollowCollectionMutation,
  useGetManufacturerCollectionsQueryState,
  useGetUserCollectionsQueryState,
} from "../api/collectionsApi";
import { useGetUserClaimsQuery } from "../api/userApi";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";

export const useCollectionShareSnackbar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackbarShown, setSnackbarShown] = useState(false);

  const manufacturerId = useAppSelector((state) => state.manufacturer.Id);
  const { searchRequest } = useAppSelector((state) => state.searchRequest);

  const location = useLocation();

  const state = location.state as { collectionId: string | undefined } | undefined;

  const { data: userClaims, isFetching: claimsFetching } = useGetUserClaimsQuery();

  const auth = useAuth();

  const hasManufacturerCollectionsAccess =
    (auth.isAuthenticated && !claimsFetching && userClaims?.ManufacturerCollections?.includes(manufacturerId)) ?? false;

  const { data: manufacturerCollections, isFetching: manufacturerCollectionFetching } =
    useGetManufacturerCollectionsQueryState(manufacturerId);

  const selectedManufacturerCollection = searchRequest.ProductSetId
    ? manufacturerCollections?.find((c) => c.CollectionId === searchRequest.ProductSetId)
    : undefined;

  useEffect(() => {
    if (state && state.collectionId && !selectedManufacturerCollection && !snackbarShown) {
      dispatch(setSearchOptionsProductSetId(state.collectionId));
      dispatch(setProductSetId(state.collectionId));
    }
  }, [state, dispatch, enqueueSnackbar, closeSnackbar, selectedManufacturerCollection, snackbarShown]);

  const {
    data: userCollections = [],
    isUninitialized: userCollectionsUninitialized,
    isFetching: userCollectionsFetching,
  } = useGetUserCollectionsQueryState(manufacturerId);

  const followingCollection = useMemo(() => {
    return (
      !hasManufacturerCollectionsAccess &&
      userCollections.some((c) => c.ProductSetId === selectedManufacturerCollection?.CollectionId)
    );
  }, [hasManufacturerCollectionsAccess, selectedManufacturerCollection?.CollectionId, userCollections]);

  const [followCollection] = useFollowCollectionMutation();

  const handleFollowCollection = useCallback(async () => {
    if (selectedManufacturerCollection && !followingCollection) {
      await followCollection(selectedManufacturerCollection.CollectionId);
      enqueueSnackbar(t("UI.Collections.Notification.Followed"), { variant: "success" });
    }
  }, [enqueueSnackbar, followCollection, followingCollection, selectedManufacturerCollection, t]);

  useEffect(() => {
    if (
      !claimsFetching &&
      !hasManufacturerCollectionsAccess &&
      !manufacturerCollectionFetching &&
      !userCollectionsUninitialized &&
      !userCollectionsFetching &&
      state &&
      state.collectionId &&
      selectedManufacturerCollection &&
      !followingCollection &&
      !snackbarShown
    ) {
      enqueueSnackbar(
        <span>
          Would you like to add collection{" "}
          <em>
            <b>{selectedManufacturerCollection?.Name}</b>
          </em>{" "}
          to a list of your collections?
        </span>,
        {
          variant: "default",
          preventDuplicate: true,
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          key: "follow-collection-snackbar",
          action: (snackbarId) => (
            <div className="notistack-snackbar-buttons-wrapper">
              <Button variant="contained" label="Yes" size="small" onClick={handleFollowCollection} />
              <Button variant="contained" label="No" size="small" onClick={() => closeSnackbar(snackbarId)} />
            </div>
          ),
        }
      );
      setSnackbarShown(true);
    }
  }, [
    claimsFetching,
    closeSnackbar,
    enqueueSnackbar,
    followingCollection,
    handleFollowCollection,
    hasManufacturerCollectionsAccess,
    manufacturerCollectionFetching,
    selectedManufacturerCollection,
    snackbarShown,
    state,
    userCollectionsFetching,
    userCollectionsUninitialized,
  ]);

  useEffect(() => {
    if (
      (state &&
        selectedManufacturerCollection &&
        state.collectionId !== selectedManufacturerCollection.CollectionId &&
        snackbarShown) ||
      followingCollection
    ) {
      closeSnackbar("follow-collection-snackbar");
    }
  }, [state, selectedManufacturerCollection, closeSnackbar, snackbarShown, followingCollection]);
};
