import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";
import { getToken } from "../utils/authUtils";

interface UserClaims {
  ManufacturerCollections?: string[];
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.userApiUrl}/`,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserClaims: builder.query<UserClaims, void>({
      query: () => `userclaims`,
    }),
  }),
});

export const { useGetUserClaimsQuery } = userApi;
