import { useAppSelector } from "./reduxHooks";
import { useGetSearchResultQueryState } from "../api/searchApi";
import { Product } from "@magicad-cloud/component-library";
import { useTelemetry } from "../telemetry-provider-react/telemetryHooks";
import { SearchResults } from "../models/searchResults";

interface useCurrentSearchResultsResponse {
  searchResults?: SearchResults;
  searchResultsLoading: boolean;
}

export const useCurrentSearchResults = (): useCurrentSearchResultsResponse => {
  const { searchRequest } = useAppSelector((store) => store.searchRequest);
  const { commonTelemetryProperties } = useTelemetry();

  const searchRequestWithTelemetryProperties = { ...searchRequest, ...commonTelemetryProperties };

  const { data, isLoading, isSuccess, isFetching } = useGetSearchResultQueryState(searchRequestWithTelemetryProperties);

  return { searchResults: data, searchResultsLoading: !isSuccess || isLoading || isFetching };
};

export const useProducts = (): Product[] => {
  const { searchResults = { Products: [] } } = useCurrentSearchResults();

  return searchResults.Products;
};

export const usePageCount = (productsPerPage: number): number => {
  const { searchResults = { Total: 0 } } = useCurrentSearchResults();

  return Math.ceil(searchResults.Total / productsPerPage);
};
