import { useAuth } from "react-oidc-context";
import { Navigate, useParams } from "react-router-dom";
import { getBasePath } from "../../helpers/urlHelpers";
import { useForcedSigninRedirect } from "../../hooks";

export const CollectionPage = () => {
  const { collectionId } = useParams();

  const auth = useAuth();

  useForcedSigninRedirect();

  const basePath = getBasePath();

  return auth.isAuthenticated ? <Navigate to={basePath} replace state={{ collectionId: collectionId }} /> : null;
};
