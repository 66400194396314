import { createSlice } from "@reduxjs/toolkit";
import { BannerValues } from "@magicad-cloud/component-library";
import { configurationApi } from "../api/configurationApi";

type ConfigurationState = {
  banners: BannerValues[];
};

const initialState: ConfigurationState = {
  banners: [],
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      configurationApi.endpoints.getBannersByLocaleAndGuid.matchFulfilled,
      (state, { payload: banners }) => {
        state.banners = banners.sort((a, b) => a.Priority - b.Priority);
      }
    );
  },
});

export default configurationSlice.reducer;
