import { PropsWithChildren } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetManufacturerQuery } from "../api/searchApi";
import {
  useManufacturerNameOrIdReader,
  useAccessTokenExpiredEventHandler,
  useAppSelector,
  useForcedLogin,
  useLocale,
  useSubscription,
} from "../hooks";
import { TelemetryProvider } from "../telemetry-provider-react/components/TelemetryProvider";
import { ManufacturerNotFoundError, UnexpectedError } from "../models/errors";
import { SpinningLogo } from "../components/spinningLogo/SpinningLogo";
import { SnackbarProvider } from "notistack";
import { closeSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const BrandedPageApp = ({ children }: PropsWithChildren) => {
  const manufacturerNameOrId = useManufacturerNameOrIdReader();

  const { isSuccess: manufacturerLoaded, error: manufacturerError } = useGetManufacturerQuery(
    manufacturerNameOrId ?? skipToken
  );

  if (manufacturerError) {
    if ("status" in manufacturerError && manufacturerError?.status === 404) {
      throw new ManufacturerNotFoundError();
    } else {
      throw new UnexpectedError();
    }
  }

  useForcedLogin();
  useLocale();
  useAccessTokenExpiredEventHandler();
  useSubscription();

  const applicationParams = useAppSelector((store) => store.applicationParams);

  return manufacturerLoaded ? (
    <TelemetryProvider applicationParams={applicationParams}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        )}
      >
        {children}
      </SnackbarProvider>
    </TelemetryProvider>
  ) : (
    <SpinningLogo />
  );
};
