import { useMemo } from "react";
import {
  AttachmentType,
  createAttachmentChip,
  createProductClassChip,
  createTextFilterChip,
  ProductClass,
  SearchBar,
  SearchBarOption,
  SearchBarOptionCategory,
} from "@magicad-cloud/component-library";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useGetSearchOptionsQueryState } from "../../api/searchApi";
import { addChip } from "../../slices/chipsSlice";
import {
  addAttachmentFilter as addAttachmentFilterToSearchOptionsRequest,
  addProductClassId,
} from "../../slices/searchOptionsRequestSlice";
import {
  addAttachmentFilter as addAttachmentFilterToSearchRequest,
  addProductClass,
  addTextFilter,
} from "../../slices/searchRequestSlice";

const productClassToSearchBarOptions = (p: ProductClass): SearchBarOption[] =>
  [
    {
      translationKey: `UI.SearchOptions.ProductClass.${p.IdentifyingName}`,
      value: String(p.Id),
      iconUrl: p.IconUrl,
      category: SearchBarOptionCategory.ProductClass,
      source: p,
    } as SearchBarOption,
  ].concat(p.Children.map(productClassToSearchBarOptions).flat());

export const SearchBarContainer = () => {
  const dispatch = useAppDispatch();

  const { searchOptionsRequest } = useAppSelector((state) => state.searchOptionsRequest);

  const { data: searchOptions } = useGetSearchOptionsQueryState(searchOptionsRequest);

  const productClassOptions = useMemo(() => {
    return searchOptions?.ProductClasses.map(productClassToSearchBarOptions).flat() ?? [];
  }, [searchOptions?.ProductClasses]);

  const bimApplicationOptions: SearchBarOption[] =
    searchOptions?.AttachmentTypes.map(
      (source) =>
        ({
          key: source.Name,
          label: source.Name,
          value: source.Id,
          iconUrl: source.IconUrl,
          category: SearchBarOptionCategory.AttachmentType,
          source,
        } as SearchBarOption)
    ) ?? [];

  const onValueChange = (value: SearchBarOption) => {
    switch (value.category) {
      case SearchBarOptionCategory.ProductClass:
        addProductClassFilterOption(value.source as ProductClass);
        break;
      case SearchBarOptionCategory.AttachmentType:
        addAttachmentFilterOption(value.source as AttachmentType);
        break;
      case SearchBarOptionCategory.FreeText:
        addTextFilterOption(value.value);
        break;
      default:
        break;
    }
  };

  const addProductClassFilterOption = (value: ProductClass) => {
    dispatch(addChip(createProductClassChip(value.IdentifyingName, value.Id)));
    dispatch(addProductClassId(value.Id));
    dispatch(addProductClass(value.Id));
  };

  const addAttachmentFilterOption = (value: AttachmentType) => {
    dispatch(addChip(createAttachmentChip(value.Name, value.Id)));
    dispatch(addAttachmentFilterToSearchOptionsRequest(value.Id));
    dispatch(addAttachmentFilterToSearchRequest(value.Id));
  };

  const addTextFilterOption = (value: string) => {
    dispatch(addChip(createTextFilterChip(value)));
    dispatch(addTextFilter(value));
  };

  const options = productClassOptions.concat(bimApplicationOptions);

  return <SearchBar options={options} onValueChange={onValueChange} />;
};
