import { createContext } from "react";
import { CommonTelemetryProperties } from "./types";

interface ITelemetryContext {
  getCommonTelemetryProperties: () => CommonTelemetryProperties;
  telemetryReady: boolean;
}

const initialContext = {
  getCommonTelemetryProperties: () => {
    throw new Error("TelemetryProvider not set up");
  },
  telemetryReady: false,
};

const TelemetryContext = createContext<ITelemetryContext>(initialContext);

export default TelemetryContext;
