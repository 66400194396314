import { ReactNode } from "react";
import { Divider } from "@mui/material";
import "./ProductNotFoundLayout.scss";

export interface ProductNotFoundLayoutProps {
  newVersionAlert: ReactNode;
  appBar: ReactNode;
  banner: ReactNode;
  productRequestNotification: ReactNode;
  footer: ReactNode;
}

export const ProductNotFoundLayout = ({
  newVersionAlert,
  appBar,
  banner,
  productRequestNotification,
  footer,
}: ProductNotFoundLayoutProps) => {
  return (
    <div className="ProductNotFoundLayout">
      <div className="ProductNotFoundLayout__feedback">{newVersionAlert}</div>
      <div className="ProductNotFoundLayout__top">
        <div className="ProductNotFoundLayout__appbar">{appBar}</div>
        <div className="ProductNotFoundLayout__banner">{banner}</div>
        <div className="ProductNotFoundLayout__divider">
          <Divider />
        </div>
      </div>
      <div className="ProductNotFoundLayout__bottom">
        <div className="ProductNotFoundLayout__product-request-notification">{productRequestNotification}</div>
      </div>
      <div className="ProductNotFoundLayout__footer">{footer}</div>
    </div>
  );
};
