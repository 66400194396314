import { Product } from "@magicad-cloud/component-library";
import { useCallback, useEffect } from "react";
import { Dataset } from "../models/dataset";
import { setDataset, setDatasetReady } from "../slices/datasetSlice";
import { useAppDispatch, useAppSelector } from "./reduxHooks";

// eslint-disable-next-line
declare const eoapi: any;

export function useDataset() {
  const connectParams = useAppSelector((store) => store.connectParams);
  const { dataset } = useAppSelector((store) => store.dataset);
  const { ApplicationType } = useAppSelector((store) => store.applicationParams);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (connectParams?.hasdatasetinfo) {
      // eslint-disable-next-line
      if (typeof eoapi !== "undefined") {
        // eslint-disable-next-line
        const result: string = eoapi.extInvoke("GetDatasetInfo");
        const resultObj = JSON.parse(result) as Dataset;
        dispatch(setDataset(resultObj));
      }
    }
    dispatch(setDatasetReady(true));
  }, [connectParams?.hasdatasetinfo, dispatch]);

  const isInserted = useCallback(
    (productQpdId?: string, variantQpdId?: string) => {
      if (productQpdId && dataset && dataset.DatasetEntries) {
        let product = dataset.DatasetEntries.find((el) => el.ProductQpdId === productQpdId);
        if (!product) {
          product = dataset.DatasetEntries.find((el) => el.ProductQpdId === variantQpdId);
          if (product?.ProductQpdId) {
            return variantQpdId === product?.ProductQpdId;
          }
        }
        if (variantQpdId) {
          if (product?.VariantQpdIds) {
            return product.VariantQpdIds.some((a) => a === variantQpdId);
          }
        }
        return productQpdId === product?.ProductQpdId;
      }
      return false;
    },
    [dataset]
  );

  const isInsertedId = useCallback(
    (productId: string) => {
      if (dataset) {
        return dataset.DatasetEntries.filter((x) => x.ProductRef === productId).length > 0;
      }
    },
    [dataset]
  );

  const isFullyInserted = useCallback(
    (product: Product) => {
      if (ApplicationType === "web-branded") {
        return false;
      }

      if (dataset && product.ProductQpdId && product.VariantQpdIds) {
        if (
          product.VariantCount &&
          product.VariantCount > 0 &&
          product.Classifications &&
          product.Classifications["MagiCAD"].MagiCadDisciplines
        ) {
          switch (product.Classifications["MagiCAD"].MagiCadDisciplines[0]) {
            case "L;2":
              if (product.ProductQpdId === "000001" && product.Id) return isInsertedId(product.Id);
              else
                return product.VariantQpdIds.every((variantQpdId: string) =>
                  isInserted(product.ProductQpdId, variantQpdId)
                );
            case "L;4":
              if (product.ProductQpdId === "000001" && product.Id) return isInsertedId(product.Id);
              else
                return product.VariantQpdIds.every((variantQpdId: string) =>
                  isInserted(product.ProductQpdId, variantQpdId)
                );
            default:
              return product.VariantQpdIds.every((variantQpdId: string) =>
                isInserted(product.ProductQpdId, variantQpdId)
              );
          }
        } else if (product.VariantCount === 0) {
          return isInserted(product.ProductQpdId);
        }
      }
      return false;
    },
    [ApplicationType, dataset, isInserted, isInsertedId]
  );

  const isPartlyInserted = useCallback(
    (product: Product) => {
      if (dataset && product.ProductQpdId && product.VariantQpdIds) {
        if (!isFullyInserted(product)) {
          return product.VariantQpdIds.filter((variantQpdId) => isInserted(product.ProductQpdId, variantQpdId)).length;
        }
      }
      return 0;
    },
    [dataset, isInserted, isFullyInserted]
  );

  const getConnectPlatformName = useCallback(() => {
    switch (connectParams?.app.platform) {
      case "MC":
        return "AutoCAD";
      case "MCE":
        return "AutoCAD Electrical";
      case "MRV":
        return "Revit";
      case "CRV":
        return "Revit";
    }
    return "Unknown";
  }, [connectParams]);

  const isUpToDateSupported = useCallback(() => {
    if (connectParams) {
      switch (connectParams?.app.platform) {
        case "MC":
          return connectParams.app.version >= 230;
        case "MCE":
          return connectParams.app.version >= 200;
        case "MRV":
          return false;
        case "CRV":
          return false;
      }
      return false;
    }
    return false;
  }, [connectParams]);

  const getProductVersion = useCallback(
    (qpdId: string) => {
      if (dataset) {
        const p = dataset.DatasetEntries.find((el) => el.ProductQpdId === qpdId);
        if (p && p.ProductQpdId === qpdId) {
          return new Date(p.VersionFormated ?? p.Version);
        }
      }
      return false;
    },
    [dataset]
  );

  //Used by Product Cards
  const hasUpdateAvailable = useCallback(
    (product: Product) => {
      if (
        isUpToDateSupported() &&
        (isFullyInserted(product) || isPartlyInserted(product)) &&
        product.ProductQpdId &&
        product.Classifications &&
        product.Classifications["MagiCAD"].MagiCadDisciplines
      ) {
        if (product.Updated) {
          let version;
          switch (product.Classifications["MagiCAD"].MagiCadDisciplines[0]) {
            case "L;8":
              if (product.VariantQpdIds) {
                const insertedVariants = product.VariantQpdIds.map((x) => getProductVersion(x));
                if (insertedVariants) {
                  // Gets the earliest date of all inserted variants
                  const earliestDate = insertedVariants.filter((x) => x || x === null) as Date[];
                  version = earliestDate.sort().at(0);
                }
              } else {
                if (product.QpdId) {
                  version = getProductVersion(product.QpdId);
                }
              }
              break;
            default:
              version = getProductVersion(product.ProductQpdId);
              break;
          }
          if (version) {
            // eslint-disable-next-line
            const updated = new Date(product.Updated);
            return updated > version;
          }
        }
      }
      return false;
    },
    [isUpToDateSupported, isFullyInserted, isPartlyInserted, getProductVersion]
  );

  //Used by Product Cards
  const hasUpdateAvailableCount = useCallback(
    (product: Product) => {
      if (
        isUpToDateSupported() &&
        (isFullyInserted(product) || isPartlyInserted(product)) &&
        product.ProductQpdId &&
        product.Classifications &&
        product.Classifications["MagiCAD"].MagiCadDisciplines
      ) {
        if (product.Updated) {
          switch (product.Classifications["MagiCAD"].MagiCadDisciplines[0]) {
            case "L;8":
              if (product.VariantQpdIds) {
                const insertedVariants = product.VariantQpdIds.map((x) => getProductVersion(x));
                const insertedVariantsWithDate = insertedVariants.filter((x) => x || x === null) as Date[];
                if (insertedVariantsWithDate) {
                  const insertedVariantsWithUpdate = insertedVariantsWithDate.map((x) => {
                    if (product.Updated) {
                      const updated = new Date(product.Updated);
                      if (updated) {
                        return updated > x;
                      }
                    }
                    return undefined;
                  });
                  return insertedVariantsWithUpdate.filter((x) => x === true).length;
                }
              } else {
                return undefined;
              }
              break;
            default:
              return undefined;
          }
        }
      }
      return undefined;
    },
    [isUpToDateSupported, isFullyInserted, isPartlyInserted, getProductVersion]
  );

  //Used by Product Details
  const hasUpdateAvailableDetails = useCallback(
    (product: Product) => {
      if (
        isUpToDateSupported() &&
        product.FamilyQpdId &&
        product.QpdId &&
        isInserted(product.FamilyQpdId, product.QpdId) &&
        product.Classifications &&
        product.Classifications["MagiCAD"].MagiCadDisciplines
      ) {
        if (product.Edited) {
          let version;
          switch (product.Classifications["MagiCAD"].MagiCadDisciplines[0]) {
            case "L;8":
              version = getProductVersion(product.QpdId);
              break;
            default:
              version = getProductVersion(product.FamilyQpdId);
              break;
          }

          if (version) {
            const updated = new Date(product.Edited);
            return updated > version;
          }
        }
      }
      return false;
    },
    [isUpToDateSupported, isInserted, getProductVersion]
  );

  //Used by Variant menu
  const hasUpdateAvailableVariant = useCallback(
    (qpdId?: string, productVersion?: string | null) => {
      if (isUpToDateSupported() && isInserted(qpdId)) {
        if (qpdId && productVersion) {
          const version = getProductVersion(qpdId);
          if (version) {
            const updated = new Date(productVersion);
            return updated > version;
          }
        }
      }
      return false;
    },
    [isUpToDateSupported, isInserted, getProductVersion]
  );

  return {
    isInserted,
    isFullyInserted,
    isPartlyInserted,
    hasUpdateAvailable,
    hasUpdateAvailableCount,
    hasUpdateAvailableDetails,
    hasUpdateAvailableVariant,
    getConnectPlatformName,
  };
}
