import { useCallback } from "react";
import { Banner, BannerButton, BannerValues } from "@magicad-cloud/component-library";
import { useAppSelector } from "../../hooks";
import { BannerEventData } from "../../telemetry-provider-react/types";
import { useTelemetry } from "../../telemetry-provider-react/telemetryHooks";
import { useLazySendTelemetryEventQuery } from "../../api/searchApi";
import { useTranslation } from "react-i18next";
import { useGetBannersByLocaleAndGuidQuery } from "../../api/configurationApi";

export const Banners = () => {
  const { i18n } = useTranslation();
  const { banners } = useAppSelector((store) => store.configuration);
  const manufacturerId = useAppSelector((store) => store.manufacturer.Id);

  useGetBannersByLocaleAndGuidQuery({ locale: i18n.language, manufacturerId });

  const { createTelemetryEvent } = useTelemetry();
  const [sendTelemetryEvent] = useLazySendTelemetryEventQuery();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    variableWidth: true,
    adaptiveHeight: true,
  };

  const sendBannerTelemetryEvent = useCallback(
    (banner: BannerValues, button: BannerButton) => {
      const eventData: BannerEventData = {
        Banner: banner.Title,
        BannerId: banner.Id,
        Button: button.Title,
        ManufacturerId: banner.ManufacturerId,
        SelectedLanguage: i18n.language,
      };

      const telemetryEvent = createTelemetryEvent({ eventType: "Banner", eventData });

      void sendTelemetryEvent(telemetryEvent);
    },
    [createTelemetryEvent, i18n.language, sendTelemetryEvent]
  );

  if (banners.length === 0) {
    return null;
  }

  return <Banner banners={banners} settings={settings} onButtonClick={sendBannerTelemetryEvent} />;
};
