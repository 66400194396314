import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";
import { SubscriptionResponse } from "../models/subscription";
import { getToken } from "../utils/authUtils";

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.subscriptionApiUrl}/`,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSubscription: builder.query<SubscriptionResponse, boolean>({
      query: () => ({
        url: "subscription",
      }),
    }),
  }),
});

export const { useGetSubscriptionQuery } = subscriptionApi;
