const nonManufacturerSubdomains = [
  "branded-local", // this is for local development
  "branded-dev",
  "branded-qa",
  "branded-prod",
  "mcloud-prod-branded-ui",
  "magicadcloud-dev-brandedui8",
];

export const isManufacturerSubdomain = (subdomain: string) => !nonManufacturerSubdomains.includes(subdomain);

export const getBasePath = () => {
  const manufacturerName = localStorage.getItem("magicadcloud.branded.manufacturer") as string;
  const subdomain = getSubdomain();

  return subdomain && isManufacturerSubdomain(subdomain) ? "" : `/home/${manufacturerName}`;
};

export const replaceSearchPath = (encodedParams: string, manufacturerName?: string) => {
  const subdomain = getSubdomain();
  if (subdomain && isManufacturerSubdomain(subdomain)) {
    window.history.replaceState({}, "", `/search/${encodedParams}`);
  } else if (manufacturerName) {
    window.history.replaceState({}, "", `/home/${manufacturerName}/search/${encodedParams}`);
  }
};

export const getSubdomain = () => {
  const splitHostnameArray = window.location.hostname.split(".");
  return splitHostnameArray.length > 1 ? splitHostnameArray[0] : undefined;
};
