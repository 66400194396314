import { useCallback, useEffect } from "react";
import { subscriptionApi } from "../api/subscriptionApi";
import { useAuth } from "react-oidc-context";
import { clearSubscription, setFromSubscriptionResponse } from "../slices/subscriptionSlice";
import { useAppDispatch } from "./reduxHooks";

// TODO: Merge subscriptionApi with userApi?

export const useSubscription = () => {
  const [subscriptionTrigger] = subscriptionApi.endpoints.getSubscription.useLazyQuery();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const refreshSubscription = useCallback(async () => {
    const { data } = await subscriptionTrigger(true, false);
    if (data) dispatch(setFromSubscriptionResponse(data));
  }, [dispatch, subscriptionTrigger]);

  useEffect(() => {
    if (auth.isAuthenticated) void refreshSubscription();
    else dispatch(clearSubscription());
  }, [auth, dispatch, refreshSubscription]);
};
