import { useMemo } from "react";
import { GenericFacet, GenericAggregate, AggregateFilter } from "@magicad-cloud/component-library";
import { FacetSkeletons } from "../facetSkeletons/FacetSkeletons";

interface GenericFacetGroupProps {
  aggregates: GenericAggregate[];
  loading: boolean;
  addAggregateFilter: (aggregateFilter: AggregateFilter) => void;
  removeAggregateFilter: (aggregateFilter: AggregateFilter) => void;
}

export const GenericFacetGroup = ({
  aggregates,
  loading,
  addAggregateFilter,
  removeAggregateFilter,
}: GenericFacetGroupProps) => {
  const genericFacets = useMemo(
    () => (
      <>
        {aggregates.map((aggregate) => (
          <GenericFacet
            key={aggregate.Name}
            aggregate={aggregate}
            addAggregateFilter={addAggregateFilter}
            removeAggregateFilter={removeAggregateFilter}
          />
        ))}
      </>
    ),
    [aggregates, addAggregateFilter, removeAggregateFilter]
  );

  return loading ? <FacetSkeletons amount={aggregates.length} /> : genericFacets;
};
