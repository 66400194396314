import { useEffect } from "react";
import { useTelemetry } from "./../telemetry-provider-react/telemetryHooks";
import { InsertEventData } from "./../telemetry-provider-react/types";
import { Product, ProductAction } from "@magicad-cloud/component-library";
import { config } from "../config/config";
import { useAccessToken } from "./authHooks";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { clearMultiInsertSelection } from "../slices/multiInsertSlice";
import { addToOrModifyDataset } from "../slices/datasetSlice";
import { useLazySendTelemetryEventQuery, useLazySendMultipleTelemetryEventsQuery } from "../api/searchApi";
import { DatasetInsert } from "../models/dataset";

interface ConnectProduct extends ProductAction {
  MagiCloudToken: string | undefined;
  MagiCloudAuthenticationScheme: string | undefined;
}

// eslint-disable-next-line
declare const eoapi: any;

export function useInsert() {
  const { fetchAccessTokenRedirect } = useAccessToken();
  const { multiInsertProducts } = useAppSelector((store) => store.multiInsert);
  const dispatch = useAppDispatch();

  const { createTelemetryEvent } = useTelemetry();
  const [sendTelemetryEvent] = useLazySendTelemetryEventQuery();
  const [sendMultipleTelemetryEvents] = useLazySendMultipleTelemetryEventsQuery();

  const applicationType = useAppSelector((state) => state.applicationParams.ApplicationType);

  const isMagiCad = applicationType === "magicad-branded";
  const isPlainRevit = applicationType === "revit-branded";
  const isEOBrowser = typeof eoapi !== "undefined";
  const selectedCollection = useAppSelector((state) => state.searchRequest.searchRequest.ProductSetId);

  useEffect(() => {
    dispatch(clearMultiInsertSelection());
  }, [selectedCollection, dispatch]);

  const isMultiInsertEnabled = isEOBrowser && isPlainRevit && selectedCollection !== null;

  const isProductSelectedForMultiInsert = useCallback(
    (p: Product) => {
      return multiInsertProducts.some((a) => a.Id === p.Id);
    },
    [multiInsertProducts]
  );

  const convertProduct = useCallback(
    async (p: Product) => {
      const accessToken = !isMagiCad ? await fetchAccessTokenRedirect() : undefined;

      if (p.Actions) {
        for (let index = 0; index < p.Actions.length; index++) {
          const element = p.Actions[index];

          if (element.ActionName === "insert") {
            return {
              ActionName: element.ActionName, // "insert",
              ProductRef: element.ProductId,
              VariantRef: element.VariantId ?? null,
              ProductQpdId: element.ProductQpdId,
              VariantQpdId: element.VariantQpdId ?? null,
              Code: element.Code,
              Permalink: getPermalink(element.ProductId),
              RfaRef: element.RfaReferences ?? [],
              ManufacturerRef: element.ManufacturerId,
              IsFamilyInsert: element.IsFamilyInsert,
              PartClassId: element.PartClassId,
              KeepOpen: true,

              MagiCloudToken: accessToken,
              MagiCloudAuthenticationScheme: "Bearer",
            } as Partial<ConnectProduct>;
          }
        }
      }
      return false;
    },
    [fetchAccessTokenRedirect, isMagiCad]
  );

  const convertProducts = useCallback(
    async (p: Product[]) => {
      const accessToken = !isMagiCad ? await fetchAccessTokenRedirect() : undefined;
      const list = [] as Partial<ConnectProduct>[];

      for (let i = 0; i < p.length; i++) {
        const product = p[i];
        const a = await convertProduct(product);
        if (a !== false) {
          list.push(a);
        } else {
          return false;
        }
      }

      return {
        ProductDataList: list,
        IsProductPackage: false,
        MagiCloudToken: accessToken,
        MagiCloudAuthenticationScheme: "Bearer",
      };
    },
    [convertProduct, fetchAccessTokenRedirect, isMagiCad]
  );

  const sendInsertTelemetryEvent = useCallback(
    (product: Product) => {
      const eventData: InsertEventData = {
        ProductId: product.ProductId || product.Id || null,
        ManufacturerId: product.ManufacturerId || (product.Manufacturer || {}).Id || null,
        MultiInsert: false,
        Lod: !!(product.LODLevels || {}).length,
        Etim: !!(product.Classifications || {}).Etim7,
        CertificationLevel: (product.Certification || {}).Level || null,
        ProductType: ((product.Classifications || {}).MagiCAD || {}).MagiCadClassId || null,
        CollectionId: selectedCollection,
      };

      const telemetryEvent = createTelemetryEvent({ eventType: "Insert", eventData });
      void sendTelemetryEvent(telemetryEvent);
    },
    [createTelemetryEvent, selectedCollection, sendTelemetryEvent]
  );

  const sendMultipleInsertTelemetryEvents = useCallback(
    (products: Product[]) => {
      const eventData: InsertEventData[] = products.map((product) => ({
        ProductId: product.ProductId || product.Id || null,
        ManufacturerId: product.ManufacturerId || (product.Manufacturer || {}).Id || null,
        MultiInsert: true,
        Lod: !!(product.LODLevels || {}).length,
        Etim: !!(product.Classifications || {}).Etim7,
        CertificationLevel: (product.Certification || {}).Level || null,
        ProductType: ((product.Classifications || {}).MagiCAD || {}).MagiCadClassId || null,
        CollectionId: selectedCollection,
      }));

      const telemetryEvents = eventData.map((e) => createTelemetryEvent({ eventType: "Insert", eventData: e }));
      void sendMultipleTelemetryEvents(telemetryEvents);
    },
    [createTelemetryEvent, selectedCollection, sendMultipleTelemetryEvents]
  );

  const insert = useCallback(
    (product: Product | null) => {
      if (!product) return;

      sendInsertTelemetryEvent(product);

      void convertProduct(product).then((p) => {
        // eslint-disable-next-line
        const result: string = eoapi.extInvoke("InsertProduct", JSON.stringify(p));

        if (isMagiCad) {
          const resultObj = JSON.parse(result) as DatasetInsert;
          if (resultObj.DatasetResult === "Successful") {
            dispatch(addToOrModifyDataset(resultObj.DatasetEntry));
          }
        }
      });
    },
    [convertProduct, dispatch, isMagiCad, sendInsertTelemetryEvent]
  );

  const multiInsert = useCallback(() => {
    if (multiInsertProducts.length === 1) {
      insert(multiInsertProducts[0]);
    } else {
      sendMultipleInsertTelemetryEvents(multiInsertProducts);
      void convertProducts(multiInsertProducts).then((p) => {
        // eslint-disable-next-line
        eoapi.extInvoke("InsertMultipleProducts", JSON.stringify(p));
      });
    }
  }, [multiInsertProducts, insert, sendMultipleInsertTelemetryEvents, convertProducts]);

  const closeConnect = useCallback(() => {
    // eslint-disable-next-line
    eoapi.extInvoke("Cancel");
  }, []);

  return {
    isMultiInsertEnabled,
    insert,
    multiInsert,
    isProductSelectedForMultiInsert,
    closeConnect,
  };
}

const getPermalink = (productId: string | undefined) => {
  if (productId) {
    return `${config.connectInsertRedirectUrl}/product/${productId}`;
  }
  return undefined;
};
