import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AggregateFilter, AttachmentOption, OperativeFilter } from "@magicad-cloud/component-library";
import {
  defaultSearchOptionsRequest,
  SearchOptionsRequest,
  searchOptionsRequestKeys,
} from "./../models/searchOptionsRequest";
import { cloneDeep, difference, differenceWith, isEqual, pick, unionBy, unionWith } from "lodash";
import { SupportedLanguage, SupportedLocale } from "../common/types";
import { searchApi } from "../api/searchApi";
import { isAuthenticated } from "../utils/authUtils";
import { SearchRequest } from "../models/searchRequest";
import { ConnectParams } from "../models/connectParams";
import { config } from "../config/config";
import { RootState } from "../app/store";

export interface SearchOptionsRequestState {
  searchOptionsRequest: SearchOptionsRequest;
  base64ParamsApplied: boolean;
}

const initialState: SearchOptionsRequestState = {
  searchOptionsRequest: cloneDeep(defaultSearchOptionsRequest),
  base64ParamsApplied: false,
};

const searchOptionsRequestSlice = createSlice({
  name: "searchOptionsRequest",
  initialState,
  reducers: {
    applyBase64ParamsToSearchOptionsRequest(
      state,
      {
        payload: { connectParams, searchParams },
      }: PayloadAction<{ connectParams?: ConnectParams; searchParams?: Partial<SearchRequest> }>
    ) {
      const searchOptionsParams = pick(searchParams, searchOptionsRequestKeys);

      const searchOptionsRequest = { ...state.searchOptionsRequest, ...searchOptionsParams };

      searchOptionsRequest.ProductSetId = isAuthenticated() ? searchOptionsRequest.ProductSetId : null;

      if (searchParams?.ProductClasses) {
        searchOptionsRequest.ProductClassIds = searchParams.ProductClasses;
      }

      if (connectParams) {
        const language = connectParams.lang.split("-")[0] as SupportedLanguage;
        searchOptionsRequest.LanguageLocale = config.languageLocales[language];
        searchOptionsRequest.MarketAreas = connectParams.area ? [connectParams.area] : [];
      }

      state.searchOptionsRequest = searchOptionsRequest;
      state.base64ParamsApplied = true;
    },
    setLanguageLocale(state, action: PayloadAction<SupportedLocale>) {
      state.searchOptionsRequest.LanguageLocale = action.payload;
    },
    setMarketAreas(state, action: PayloadAction<string>) {
      state.searchOptionsRequest.MarketAreas = [action.payload];
    },
    addAggregateFilter(state, action: PayloadAction<AggregateFilter>) {
      state.searchOptionsRequest.AggregateFilters = unionWith(
        state.searchOptionsRequest.AggregateFilters,
        [action.payload],
        isEqual
      );
    },
    removeAggregateFilter(state, action: PayloadAction<AggregateFilter>) {
      state.searchOptionsRequest.AggregateFilters = differenceWith(
        state.searchOptionsRequest.AggregateFilters,
        [action.payload],
        isEqual
      );
    },
    addAttachmentFilter(state, action: PayloadAction<AttachmentOption>) {
      state.searchOptionsRequest.AttachmentTypes = unionWith(
        state.searchOptionsRequest.AttachmentTypes,
        [action.payload],
        isEqual
      );
    },
    removeAttachmentFilter(state, action: PayloadAction<AttachmentOption>) {
      state.searchOptionsRequest.AttachmentTypes = differenceWith(
        state.searchOptionsRequest.AttachmentTypes,
        [action.payload],
        isEqual
      );
    },
    addOperativeFilter(state, action: PayloadAction<OperativeFilter>) {
      state.searchOptionsRequest.OperativeFilters = unionBy(
        [action.payload],
        state.searchOptionsRequest.OperativeFilters,
        "Name"
      );
    },
    removeOperativeFilter(state, action: PayloadAction<OperativeFilter>) {
      state.searchOptionsRequest.OperativeFilters = differenceWith(
        state.searchOptionsRequest.OperativeFilters,
        [action.payload],
        isEqual
      );
    },
    addProductClassId(state, action: PayloadAction<number>) {
      state.searchOptionsRequest.ProductClassIds = unionBy(
        [action.payload],
        state.searchOptionsRequest.ProductClassIds
      );
    },
    removeProductClassId(state, action: PayloadAction<number>) {
      state.searchOptionsRequest.ProductClassIds = difference(state.searchOptionsRequest.ProductClassIds, [
        action.payload,
      ]);
    },
    setSearchOptionsProductSetId(state, action: PayloadAction<string | null>) {
      state.searchOptionsRequest.ProductSetId = action.payload;
    },
    clearFacetFilters(state) {
      state.searchOptionsRequest.AggregateFilters = defaultSearchOptionsRequest.AggregateFilters;
      state.searchOptionsRequest.AttachmentTypes = defaultSearchOptionsRequest.AttachmentTypes;
      state.searchOptionsRequest.OperativeFilters = state.searchOptionsRequest.OperativeFilters.filter(
        (f) => f.Name === "Attenuation" || f.Name === "Ventilation operation point"
      );
      state.searchOptionsRequest.ProductClassIds = defaultSearchOptionsRequest.ProductClassIds;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(searchApi.endpoints.getManufacturer.matchFulfilled, (state, { payload: manufacturer }) => {
      state.searchOptionsRequest.Manufacturers = [manufacturer.Id];
    });
  },
});

export const searchOptionsRequestValidSelector = createSelector(
  (state: RootState) => state,
  (state) =>
    state.searchOptionsRequest.base64ParamsApplied &&
    isEqual(state.searchOptionsRequest.searchOptionsRequest.Manufacturers, [state.manufacturer.Id])
);

export const {
  applyBase64ParamsToSearchOptionsRequest,
  setLanguageLocale: setSearchOptionsRequestLanguageLocale,
  setMarketAreas: setSearchOptionsRequestMarketAreas,
  addAggregateFilter,
  removeAggregateFilter,
  addAttachmentFilter,
  removeAttachmentFilter,
  addOperativeFilter,
  removeOperativeFilter,
  addProductClassId,
  removeProductClassId,
  setSearchOptionsProductSetId,
  clearFacetFilters,
} = searchOptionsRequestSlice.actions;
export default searchOptionsRequestSlice.reducer;
