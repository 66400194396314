import {
  createVentilationOperationPointFilterChip,
  VentilationOperationPointFilter,
  VentilationOperationPointMenu,
  VentilationOperationPointValues,
} from "@magicad-cloud/component-library";
import { useAppDispatch, useAppSelector, useCurrentSearchOptionsResult, useCurrentSearchResults } from "../../../hooks";
import { addOperativeFilter as addOperativeFilterToSearchOptionsRequest } from "../../../slices/searchOptionsRequestSlice";
import {
  addOperativeFilter as addOperativeFilterToSearchRequest,
  ventilationOperationPointFilterSelector,
} from "../../../slices/searchRequestSlice";
import { addOperativeCustomFilterChip } from "../../../slices/chipsSlice";

export const VentilationOperationPointFilterContainer = () => {
  const dispatch = useAppDispatch();
  const { searchOptionsResultLoading } = useCurrentSearchOptionsResult();
  const { searchResultsLoading } = useCurrentSearchResults();

  const activeVentilationOperationPointFilter = useAppSelector((state) =>
    ventilationOperationPointFilterSelector(state)
  );

  const onSubmit = (data: VentilationOperationPointValues) => {
    const ventilationOperationPointFilter: VentilationOperationPointFilter = {
      Name: "Ventilation operation point",
      Values: data,
    };

    dispatch(addOperativeCustomFilterChip(createVentilationOperationPointFilterChip(ventilationOperationPointFilter)));
    dispatch(addOperativeFilterToSearchOptionsRequest(ventilationOperationPointFilter));
    dispatch(addOperativeFilterToSearchRequest(ventilationOperationPointFilter));
  };

  return (
    <VentilationOperationPointMenu
      values={activeVentilationOperationPointFilter?.Values as VentilationOperationPointValues}
      disabled={searchOptionsResultLoading || searchResultsLoading}
      onSubmit={onSubmit}
    />
  );
};
