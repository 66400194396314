import { configureStore } from "@reduxjs/toolkit";
import applicationParamsReducer from "../slices/applicationParamsSlice";
import configurationReducer from "../slices/configurationSlice";
import chipsReducer from "../slices/chipsSlice";
import manufacturerReducer from "../slices/manufacturerSlice";
import measurementUnitsReducer from "../slices/measurementUnitsSlice";
import searchOptionsRequestReducer from "../slices/searchOptionsRequestSlice";
import searchRequestReducer from "../slices/searchRequestSlice";
import multiInsertReducer from "../slices/multiInsertSlice";
import openedProductReducer from "../slices/openedProductSlice";
import datasetReducer from "../slices/datasetSlice";
import connectParamsReducer from "../slices/connectParamsSlice";
import subscriptionReducer from "../slices/subscriptionSlice";

import { searchApi } from "../api/searchApi";
import { collectionsApi } from "../api/collectionsApi";
import { productDataApi } from "../api/productDataApi";
import { configurationApi } from "../api/configurationApi";
import { userApi } from "../api/userApi";
import { subscriptionApi } from "../api/subscriptionApi";

export const store = configureStore({
  reducer: {
    applicationParams: applicationParamsReducer,
    configuration: configurationReducer,
    chips: chipsReducer,
    manufacturer: manufacturerReducer,
    measurementUnits: measurementUnitsReducer,
    searchOptionsRequest: searchOptionsRequestReducer,
    searchRequest: searchRequestReducer,
    multiInsert: multiInsertReducer,
    openedProduct: openedProductReducer,
    dataset: datasetReducer,
    connectParams: connectParamsReducer,
    subscription: subscriptionReducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [collectionsApi.reducerPath]: collectionsApi.reducer,
    [productDataApi.reducerPath]: productDataApi.reducer,
    [configurationApi.reducerPath]: configurationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(searchApi.middleware)
      .concat(collectionsApi.middleware)
      .concat(productDataApi.middleware)
      .concat(configurationApi.middleware)
      .concat(userApi.middleware)
      .concat(subscriptionApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
