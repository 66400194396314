import { AggregateFilter, AttachmentOption, OperativeFilter } from "@magicad-cloud/component-library";
import { keys } from "lodash";
import { SupportedLocale } from "../common/types";

export interface SearchOptionsRequest {
  LanguageLocale: SupportedLocale;
  Manufacturers: string[];
  MarketAreas: [] | [string];
  ProductClassIds: number[];
  AggregateFilters: AggregateFilter[];
  AttachmentTypes: AttachmentOption[];
  OperativeFilters: OperativeFilter[];
  ProductSetId: string | null;
}

export const defaultSearchOptionsRequest: SearchOptionsRequest = {
  LanguageLocale: "en-GB",
  Manufacturers: [],
  MarketAreas: [],
  ProductClassIds: [],
  AggregateFilters: [],
  AttachmentTypes: [],
  OperativeFilters: [],
  ProductSetId: null,
};

export const searchOptionsRequestKeys = keys(defaultSearchOptionsRequest);
