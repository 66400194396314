import { Fragment, useMemo, useState } from "react";
import {
  useAppSelector,
  useBase64ParamsReader,
  useBase64ParamsWriter,
  useCurrentSearchOptionsResult,
  useCurrentSearchResults,
  useDataset,
  useFacetDrawerControls,
  useUrlShortener,
} from "../../hooks";
import { useGetSearchOptionsQuery, useGetSearchResultQuery } from "../../api/searchApi";
import {
  Button,
  CollectionFormDialog,
  CollectionFormValues,
  CollectionShareDialog,
  CollectionShareValues,
  PrivacyPolicyFooter,
} from "@magicad-cloud/component-library";
import SearchFilters from "../../components/filters/searchFilters/SearchFilters";
import { LinearProgress, Typography } from "@mui/material";
import { searchRequestValidSelector } from "../../slices/searchRequestSlice";
import { FilterChips } from "../../components/filterChips/FilterChips";
import { SearchBarContainer } from "../../components/searchBarContainer/SearchBarContainer";
import { ProductCards } from "../../components/productCards/ProductCards";
import { FilterControls } from "../../components/filterControls/FilterControls";
import { AppBarContainer } from "../../components/appBarContainer/AppBarContainer";
import { Banners } from "../../components/banners/Banners";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAuth } from "react-oidc-context";
import { searchOptionsRequestValidSelector } from "../../slices/searchOptionsRequestSlice";
import { MainLayout, MainLayoutProps } from "../../layouts/mainLayout/MainLayout";
import { ProductRequestContainer } from "../../components/productRequestContainer/ProductRequestContainer";
import { SignupDialogContainer } from "../../components/signupDialogContainer/SignupDialogContainer";
import { Pagination } from "../../components/pagination/Pagination";
import { ResultInfo } from "../../components/resultInfo/ResultInfo";
import { useTelemetry } from "../../telemetry-provider-react/telemetryHooks";
import { NewVersionAlert } from "../../components/newVersionAlert/NewVersionAlert";
import {
  useEditCollectionMutation,
  useFollowCollectionMutation,
  useGetManufacturerCollectionsQueryState,
  useGetUserCollectionsQueryState,
  useInviteMemberMutation,
  useUnfollowCollectionMutation,
} from "../../api/collectionsApi";
import { useCollectionShareSnackbar } from "../../hooks/collectionHooks";
import { getBasePath } from "../../helpers/urlHelpers";
import { useGetUserClaimsQuery } from "../../api/userApi";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Notifications } from "../../components/notifications/Notifications";

export const MainPage = () => {
  const { t } = useTranslation();
  useUrlShortener();
  useBase64ParamsReader();
  useBase64ParamsWriter();

  useCollectionShareSnackbar();
  useDataset();

  const auth = useAuth();

  const { commonTelemetryProperties, telemetryReady } = useTelemetry();

  const [productRequestOpen, setProductRequestOpen] = useState(false);
  const [signupDialogOpen, setSignupDialogOpen] = useState(false);

  const searchOptionsRequestReady = useAppSelector((state) => searchOptionsRequestValidSelector(state));
  const searchRequestValid = useAppSelector((state) => searchRequestValidSelector(state));

  const { searchOptionsRequest } = useAppSelector((store) => store.searchOptionsRequest);
  const { searchRequest } = useAppSelector((store) => store.searchRequest);
  const { datasetReady } = useAppSelector((store) => store.dataset);

  useGetSearchOptionsQuery(searchOptionsRequestReady ? searchOptionsRequest : skipToken);

  const searchRequestWithTelemetryProperties = { ...searchRequest, ...commonTelemetryProperties };

  useGetSearchResultQuery(
    searchRequestValid && telemetryReady && datasetReady ? searchRequestWithTelemetryProperties : skipToken
  );

  const { searchOptionsResultLoading } = useCurrentSearchOptionsResult();
  const { searchResults, searchResultsLoading } = useCurrentSearchResults();

  const loading = searchOptionsResultLoading || searchResultsLoading;

  const handleProductRequestOpen = () => {
    auth.isAuthenticated ? setProductRequestOpen(true) : setSignupDialogOpen(true);
  };

  const linearProgress = loading ? <LinearProgress /> : null;
  const newVersionAlert = <NewVersionAlert />;
  const appBar = <AppBarContainer handleProductRequestOpen={handleProductRequestOpen} />;
  const banner = <Banners />;
  const searchBar = <SearchBarContainer />;
  const searchFilters = <SearchFilters setSignupDialogOpen={() => setSignupDialogOpen(true)} />;

  const facetDrawerControls = useFacetDrawerControls();
  const filterControls = <FilterControls facetDrawerControls={facetDrawerControls} />;

  const chips = (
    <FilterChips
      handleFacetDrawerOpenWithProductGroupsExpanded={() => {
        facetDrawerControls.handleProductGroupsExpand(true);
        facetDrawerControls.handleFacetDrawerOpen();
      }}
    />
  );

  const manufacturerId = useAppSelector((store) => store.manufacturer.Id);
  const { data: manufacturerCollections } = useGetManufacturerCollectionsQueryState(manufacturerId);

  const selectedManufacturerCollection = searchRequest.ProductSetId
    ? manufacturerCollections?.find((c) => c.CollectionId === searchRequest.ProductSetId)
    : undefined;

  const [collectionShareDialogOpen, setCollectionShareDialogOpen] = useState(false);

  const [followCollection] = useFollowCollectionMutation();
  const [unfollowCollection] = useUnfollowCollectionMutation();
  const [inviteMember] = useInviteMemberMutation();

  const { data: userCollections = [] } = useGetUserCollectionsQueryState(manufacturerId);

  const { data: userClaims } = useGetUserClaimsQuery();
  const hasManufacturerCollectionsAccess = userClaims?.ManufacturerCollections?.includes(manufacturerId) ?? false;

  const followingCollection = useMemo(() => {
    return (
      !hasManufacturerCollectionsAccess &&
      userCollections.some((c) => c.ProductSetId === selectedManufacturerCollection?.CollectionId)
    );
  }, [hasManufacturerCollectionsAccess, selectedManufacturerCollection?.CollectionId, userCollections]);

  const [hovered, setHovered] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const toggleFollowCollection = async () => {
    if (selectedManufacturerCollection && !followingCollection) {
      try {
        await followCollection(selectedManufacturerCollection.CollectionId).unwrap();
        enqueueSnackbar(t("UI.Collections.Notification.Followed"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar("Internal server error, please try again later", {
          variant: "error",
        });
      }
    }
    if (selectedManufacturerCollection && followingCollection) {
      try {
        await unfollowCollection(selectedManufacturerCollection.CollectionId).unwrap();
        enqueueSnackbar(t("UI.Collections.Notification.Unfollowed"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar("Internal server error, please try again later", {
          variant: "error",
        });
      }
    }
  };

  const handleInviteMember = async ({ email, language }: CollectionShareValues) => {
    if (selectedManufacturerCollection) {
      try {
        await inviteMember({
          collectionId: selectedManufacturerCollection.CollectionId,
          email,
          language,
          amountOfProducts: selectedManufacturerCollection.ProductIds.length,
        }).unwrap();
        enqueueSnackbar(t("UI.Collections.Notification.Invited"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar("Internal server error, please try again later", { variant: "error" });
      }
    }
  };

  const [collectionFormDialogOpen, setCollectionFormDialogOpen] = useState(false);

  const [editCollection] = useEditCollectionMutation();

  const handleCollectionFormSubmit = async (data: CollectionFormValues) => {
    if (selectedManufacturerCollection) {
      await editCollection({ data, collectionId: selectedManufacturerCollection.CollectionId });
      setCollectionFormDialogOpen(false);
    }
  };

  const collectionInfo = selectedManufacturerCollection && !loading && (
    <>
      <div className="ManufacturerCollectionInfo">
        <div className="ManufacturerCollectionInfo__row">
          <Typography variant="h5" className="ManufacturerCollectionInfo__name">
            {selectedManufacturerCollection.Name}
          </Typography>
        </div>
        {selectedManufacturerCollection.Description && (
          <Typography variant="body2" className="ManufacturerCollectionInfo__description">
            {selectedManufacturerCollection.Description}
          </Typography>
        )}
        <div className="ManufacturerCollectionInfo__buttons">
          {hasManufacturerCollectionsAccess ? (
            <Button
              variant={"contained"}
              label={"Edit"}
              size="small"
              disableElevation
              sx={{ width: "100px" }}
              onClick={() => setCollectionFormDialogOpen(true)}
            />
          ) : (
            <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
              {followingCollection ? (
                hovered ? (
                  <Button
                    variant={"outlined"}
                    label="Common.Button.Unfollow"
                    size="small"
                    disableElevation
                    sx={{ width: "120px" }}
                    color="warning"
                    onClick={toggleFollowCollection}
                  />
                ) : (
                  <Button
                    variant={followingCollection ? "outlined" : "contained"}
                    label="Common.Button.Following"
                    size="small"
                    disableElevation
                    sx={{ width: "120px" }}
                    onClick={toggleFollowCollection}
                  />
                )
              ) : (
                <Button
                  variant="contained"
                  label="Common.Button.Follow"
                  size="small"
                  disableElevation
                  sx={{ width: "120px" }}
                  onClick={toggleFollowCollection}
                />
              )}
            </div>
          )}
          <Button
            label="Common.Button.Share"
            size="small"
            disableElevation
            sx={{ width: "120px" }}
            onClick={() => setCollectionShareDialogOpen(true)}
          />
        </div>
      </div>
      <CollectionShareDialog
        open={collectionShareDialogOpen}
        collection={selectedManufacturerCollection}
        collectionBaseUrl={`${window.location.origin}${getBasePath()}/collection`}
        onInviteSend={handleInviteMember}
        onInviteRemove={() => null}
        handleClose={() => setCollectionShareDialogOpen(false)}
      />
      <CollectionFormDialog
        open={collectionFormDialogOpen}
        collection={selectedManufacturerCollection}
        hasManufacturerCollectionsAccess={hasManufacturerCollectionsAccess}
        handleClose={() => setCollectionFormDialogOpen(false)}
        onSubmit={handleCollectionFormSubmit}
      />
    </>
  );

  const resultInfo = <ResultInfo handleProductRequestOpen={handleProductRequestOpen} />;
  const topPagination = <Pagination variant="top" />;

  const productCards = (
    <ProductCards
      handleSignupDialogOpen={() => setSignupDialogOpen(true)}
      productRequestOpen={productRequestOpen}
      signupDialogOpen={signupDialogOpen}
      handleProductRequestOpen={handleProductRequestOpen}
      onProductRequestClose={() => setProductRequestOpen(false)}
      onSignupDialogClose={() => setSignupDialogOpen(false)}
    />
  );

  const bottomPagination = loading || searchResults?.Count === 0 ? null : <Pagination variant="bottom" />;
  const footer = <PrivacyPolicyFooter />;
  const notifications = <Notifications />;

  const layoutProps: MainLayoutProps = {
    linearProgress,
    newVersionAlert,
    appBar,
    banner,
    searchBar,
    searchFilters,
    filterControls,
    chips,
    collectionInfo,
    resultInfo,
    topPagination,
    productCards,
    bottomPagination,
    footer,
    notifications,
  };

  return (
    <Fragment>
      <MainLayout {...layoutProps} />
      <ProductRequestContainer open={productRequestOpen} handleClose={() => setProductRequestOpen(false)} />
      <SignupDialogContainer open={signupDialogOpen} handleClose={() => setSignupDialogOpen(false)} />
    </Fragment>
  );
};
