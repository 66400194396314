import { useEffect, useState } from "react";
import { useAppSelector } from "./reduxHooks";

export const useCurrentPage = (): number => {
  const { searchRequest } = useAppSelector((store) => store.searchRequest);
  const [currentPage, setCurrentPage] = useState<number>(searchRequest.From);

  useEffect(() => {
    setCurrentPage(searchRequest.From / 50 + 1);
  }, [searchRequest.From]);

  return currentPage;
};
