import { useState } from "react";
import { Pagination as MuiPagination, Skeleton } from "@mui/material";
import { useAppDispatch, useCurrentSearchResults, useCurrentPage, usePageCount } from "../../hooks";
import { setFrom } from "../../slices/searchRequestSlice";

interface PaginationProps {
  variant?: "top" | "bottom";
}

const getSize = (variant?: "top" | "bottom") => {
  if (!variant || variant === "top") return "medium";
  if (variant === "bottom") return "large";
};

export const Pagination = ({ variant }: PaginationProps) => {
  const dispatch = useAppDispatch();
  const { searchResults, searchResultsLoading } = useCurrentSearchResults();

  // TODO: Maybe add a products per page menu later on?
  const [productsPerPage] = useState<number>(50);
  const currentPage = useCurrentPage();
  const pageCount = usePageCount(productsPerPage);

  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setFrom((page - 1) * 50));

    if (variant === "bottom") {
      window.scrollTo(0, 0);
    }
  };

  if (variant === "top" && searchResultsLoading) {
    return <Skeleton variant="rectangular" width={418} height={32} />;
  }

  if (!searchResultsLoading && searchResults && searchResults?.Total > 0) {
    return (
      <MuiPagination
        count={pageCount}
        page={currentPage}
        shape="rounded"
        color="primary"
        size={getSize(variant)}
        showFirstButton
        showLastButton
        onChange={handlePageChange}
      />
    );
  }

  return null;
};
