import { useCallback, useContext, useMemo } from "react";
import TelemetryContext from "./telemetryContext";
import { TelemetryEventOf, TelemetryEventInput } from "./types";

export const useTelemetry = () => {
  const telemetryContext = useContext(TelemetryContext);

  const commonTelemetryProperties = useMemo(() => telemetryContext.getCommonTelemetryProperties(), [telemetryContext]);

  const createTelemetryEvent = useCallback(
    <T extends TelemetryEventInput>({ eventType, eventData }: T): TelemetryEventOf<T> => {
      const commonTelemetryProperties = telemetryContext.getCommonTelemetryProperties();

      return {
        ...commonTelemetryProperties,
        EventType: eventType,
        Data: eventData,
      } as TelemetryEventOf<T>;
    },
    [telemetryContext]
  );

  return { commonTelemetryProperties, createTelemetryEvent, telemetryReady: telemetryContext.telemetryReady };
};
