import { useAppSelector } from "./reduxHooks";
import { useGetSearchOptionsQueryState } from "../api/searchApi";
import { SearchOptionsResult } from "../models/searchOptionsResult";

interface useCurrentSearchOptionsResultResponse {
  searchOptionsResult?: SearchOptionsResult;
  searchOptionsResultLoading: boolean;
}

export const useCurrentSearchOptionsResult = (): useCurrentSearchOptionsResultResponse => {
  const { searchOptionsRequest } = useAppSelector((store) => store.searchOptionsRequest);

  const { data, isLoading, isFetching, isSuccess } = useGetSearchOptionsQueryState(searchOptionsRequest);

  return { searchOptionsResult: data, searchOptionsResultLoading: !isSuccess || isLoading || isFetching };
};
