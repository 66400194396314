import { Button } from "@magicad-cloud/component-library";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ProductFromCollectionRemoveDialogProps {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

export const ProductFromCollectionRemoveDialog = ({
  open,
  handleConfirm,
  handleClose,
}: ProductFromCollectionRemoveDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} className="CollectionManagementDeleteDialog">
      <DialogTitle>{t("UI.DeleteProductSet.ConfirmationDeleteProductSet")}</DialogTitle>
      <DialogContent className="CollectionManagementDeleteDialog__content">
        <DialogContentText>Are you sure you want to delete selected products from collection?</DialogContentText>
      </DialogContent>
      <DialogActions className="CollectionManagementDeleteDialog-actions">
        <Button variant="outlined" color="neutral" fullWidth onClick={handleClose}>
          {t("Cancel")}
        </Button>
        <Button variant="contained" disableElevation fullWidth onClick={handleConfirm}>
          {t("Remove")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
