import { Box, Divider, Skeleton } from "@mui/material";

interface FacetSkeletonsProps {
  amount: number;
}

export const FacetSkeletons = ({ amount }: FacetSkeletonsProps) => {
  const array = Array(amount).fill(null);

  return (
    <>
      {array.map((_, index) => (
        <Box component="div" key={index} sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <Skeleton animation="wave" width="100%" height={48} />
          <Divider />
        </Box>
      ))}
    </>
  );
};
