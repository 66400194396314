import { useCallback, useEffect, useRef } from "react";
import { useAuth } from "react-oidc-context";
import { decodeBase64 } from "../utils/base64Utils";

interface useAccessTokenResponse {
  fetchAccessToken: () => Promise<string>;
  fetchAccessTokenRedirect: () => Promise<string | undefined>;
  fetchUserAccess: () => Promise<userAccess>;
}

export interface userAccess {
  hasHubAccess: boolean;
  hasAccessManagementAccess: boolean;
}

export const useAccessToken = (): useAccessTokenResponse => {
  const auth = useAuth();

  const fetchAccessToken = useCallback(async (): Promise<string> => {
    if (auth.isAuthenticated && auth.user) return auth.user.access_token;

    const user = await auth.signinPopup();
    return user.access_token;
  }, [auth]);

  const fetchAccessTokenRedirect = useCallback(async (): Promise<string | undefined> => {
    if (auth.isAuthenticated && auth.user) return auth.user.access_token;
    await auth.signinRedirect();
  }, [auth]);

  const getUserClaim = useCallback(
    async (claimName: string) => {
      if (auth.isAuthenticated) {
        const token = await fetchAccessToken();
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          decodeBase64(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        /* eslint-disable */
        const decodedPayload = JSON.parse(jsonPayload);
        const claim = decodedPayload[claimName];
        if (claim === undefined) return [] as string[];
        return (typeof claim == "object" ? decodedPayload[claimName] : Array(claim)) as string[];
        /* eslint-enable */
      }
    },
    [auth.isAuthenticated, fetchAccessToken]
  );

  const fetchUserAccess = useCallback(async () => {
    if (auth.isAuthenticated) {
      let hasHubAccess = false;
      let hasAccessManagementAccess = false;

      const hubRole = await getUserClaim("magicad.cloud.manufacturer.hublogin");
      const hubAdmin = await getUserClaim("role");
      hasHubAccess = (hubAdmin || []).includes("magicad.manufacturerhub.admin") || (hubRole || []).length > 0;

      const AccessManagementRole = await getUserClaim("magicad.useraccessmanagement.admin");
      hasAccessManagementAccess = (AccessManagementRole || []).length > 0;

      const ua = {} as userAccess;
      ua.hasHubAccess = hasHubAccess;
      ua.hasAccessManagementAccess = hasAccessManagementAccess;
      return ua;
    }
    return { hasHubAccess: false, hasAccessManagementAccess: false } as userAccess;
  }, [auth, getUserClaim]);

  return { fetchAccessToken, fetchAccessTokenRedirect, fetchUserAccess };
};

export const useAccessTokenExpiredEventHandler = () => {
  const auth = useAuth();

  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      return auth.removeUser();
    });
  }, [auth]);
};

export const useSigninRedirect = () => {
  const auth = useAuth();

  const handleLogin = () => {
    localStorage.setItem("magicadcloud.branded.path", window.location.pathname);
    void auth.signinRedirect();
  };

  return handleLogin;
};

export const useForcedSigninRedirect = () => {
  const auth = useAuth();
  const loginForcedContainer = useRef(false);
  const handleLogin = useSigninRedirect();

  useEffect(() => {
    if (!loginForcedContainer.current) {
      if (!auth.isLoading) {
        loginForcedContainer.current = true;
        if (!auth.isAuthenticated) handleLogin();
      }
    }
  }, [auth, handleLogin]);
};

export const useSignoutRedirect = () => {
  const auth = useAuth();

  const handleLogout = () => {
    localStorage.setItem("magicadcloud.branded.path", window.location.pathname);
    void auth.signoutRedirect();
  };

  return handleLogout;
};
