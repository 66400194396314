import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OpenedProductState {
  productId: string | null;
  variantId: string | null;
}

const initialState: OpenedProductState = {
  productId: null,
  variantId: null,
};

const openedProductSlice = createSlice({
  name: "openedProduct",
  initialState,
  reducers: {
    setOpenedProduct(state, action: PayloadAction<string | null>) {
      state.productId = action.payload;
    },
    setOpenedVariant(state, action: PayloadAction<string | null>) {
      state.variantId = action.payload;
    },
    clearOpenedProduct() {
      return initialState;
    },
  },
});

export const { setOpenedProduct, setOpenedVariant, clearOpenedProduct } = openedProductSlice.actions;

export default openedProductSlice.reducer;
