import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupportedLanguage, SupportedLocale } from "../common/types";
import { AggregateFilter, AttachmentOption, OperativeFilter, SortOption } from "@magicad-cloud/component-library";
import { searchApi } from "../api/searchApi";
import { cloneDeep, difference, differenceWith, isEqual, union, unionBy, unionWith } from "lodash";
import { isAuthenticated } from "../utils/authUtils";
import { RootState } from "../app/store";
import { defaultSearchRequest, SearchRequest } from "../models/searchRequest";
import { ConnectParams } from "../models/connectParams";
import { config } from "../config/config";
import { Application } from "../models/application";
import { Dataset, DatasetEntry, datasetToDatasetEntriesFormatted, toDatasetEntryFormatted } from "../models/dataset";
import { addToOrModifyDataset, setDataset } from "./datasetSlice";

interface SearchRequestState {
  searchRequest: SearchRequest;
  base64ParamsApplied: boolean;
}

const initialState: SearchRequestState = {
  searchRequest: cloneDeep(defaultSearchRequest),
  base64ParamsApplied: false,
};

const getApplicationsFromConnectParams = (connectParams: ConnectParams) => {
  const applications: Application[] = [
    {
      Id: connectParams.app.id,
      App: connectParams.app.platform,
      Version: connectParams.app.version ?? null,
      PluginVersion: connectParams.pluginver,
      ControllerVersion: connectParams.ctrlver,
      ConnectPlatform: connectParams.platform,
    },
  ];

  if (connectParams.app.platforms && connectParams.app.platforms.length > 0) {
    connectParams.app.platforms
      .filter((platform) => platform !== connectParams.app.platform)
      .forEach((platform) =>
        applications.push({
          App: platform,
          Version: connectParams.app.version ?? null,
        })
      );
  }

  return applications;
};

const isValidSortOption = (option: string): option is SortOption =>
  ["SearchScore", "MagiCloudCode", "Updated"].includes(option);

const searchRequestSlice = createSlice({
  name: "searchRequest",
  initialState,
  reducers: {
    applyBase64ParamsToSearchRequest(
      state,
      {
        payload: { connectParams, searchParams },
      }: PayloadAction<{ connectParams?: ConnectParams; searchParams?: Partial<SearchRequest> }>
    ) {
      const searchRequest = { ...state.searchRequest, ...searchParams };

      searchRequest.ProductSetId = isAuthenticated() ? searchRequest.ProductSetId : null;

      if (connectParams) {
        searchRequest.Applications = getApplicationsFromConnectParams(connectParams);

        const language = connectParams.lang.split("-")[0] as SupportedLanguage;
        searchRequest.LanguageLocale = config.languageLocales[language];
        searchRequest.MarketAreas = [connectParams.area];
      }

      if (!isValidSortOption(searchRequest.SortBy)) {
        searchRequest.SortBy = "SearchScore";
      }

      state.searchRequest = searchRequest;
      state.base64ParamsApplied = true;
    },
    addAggregateFilter(state, action: PayloadAction<AggregateFilter>) {
      state.searchRequest.From = 0;
      state.searchRequest.AggregateFilters = unionWith(state.searchRequest.AggregateFilters, [action.payload], isEqual);
    },
    removeAggregateFilter(state, action: PayloadAction<AggregateFilter>) {
      state.searchRequest.From = 0;
      state.searchRequest.AggregateFilters = differenceWith(
        state.searchRequest.AggregateFilters,
        [action.payload],
        isEqual
      );
    },
    addAttachmentFilter(state, action: PayloadAction<AttachmentOption>) {
      state.searchRequest.From = 0;
      state.searchRequest.AttachmentTypes = unionWith(state.searchRequest.AttachmentTypes, [action.payload], isEqual);
    },
    removeAttachmentFilter(state, action: PayloadAction<AttachmentOption>) {
      state.searchRequest.From = 0;
      state.searchRequest.AttachmentTypes = differenceWith(
        state.searchRequest.AttachmentTypes,
        [action.payload],
        isEqual
      );
    },
    addOperativeFilter(state, action: PayloadAction<OperativeFilter>) {
      state.searchRequest.From = 0;
      state.searchRequest.OperativeFilters = unionBy([action.payload], state.searchRequest.OperativeFilters, "Name");
    },
    removeOperativeFilter(state, action: PayloadAction<OperativeFilter>) {
      state.searchRequest.From = 0;
      state.searchRequest.OperativeFilters = differenceWith(
        state.searchRequest.OperativeFilters,
        [action.payload],
        isEqual
      );
    },
    addProductClass(state, action: PayloadAction<number>) {
      state.searchRequest.From = 0;
      state.searchRequest.ProductClasses = unionWith(state.searchRequest.ProductClasses, [action.payload], isEqual);
    },
    removeProductClass(state, action: PayloadAction<number>) {
      state.searchRequest.From = 0;
      state.searchRequest.ProductClasses = differenceWith(
        state.searchRequest.ProductClasses,
        [action.payload],
        isEqual
      );
    },
    addProductGroups(state, action: PayloadAction<number[]>) {
      state.searchRequest.From = 0;
      state.searchRequest.ProductGroups = union(state.searchRequest.ProductGroups, action.payload);
    },
    removeProductGroups(state, action: PayloadAction<number[]>) {
      state.searchRequest.From = 0;
      state.searchRequest.ProductGroups = difference(state.searchRequest.ProductGroups, action.payload);
    },
    addTextFilter(state, action: PayloadAction<string>) {
      const keywordToAdd = action.payload.trim().toLocaleLowerCase();

      const existingKeywordsAsArray = state.searchRequest.Text.split(" ").filter(Boolean);

      if (existingKeywordsAsArray.length === 0) {
        state.searchRequest.From = 0;
        state.searchRequest.Text = keywordToAdd;
      }

      if (existingKeywordsAsArray.length > 0 && !existingKeywordsAsArray.includes(keywordToAdd)) {
        state.searchRequest.From = 0;
        state.searchRequest.Text = existingKeywordsAsArray.concat(keywordToAdd).join(" ");
      }
    },
    removeTextFilter(state, action: PayloadAction<string>) {
      const keywordToRemove = action.payload.trim().toLocaleLowerCase();

      const existingKeywordsAsArray = state.searchRequest.Text.split(" ").filter(Boolean);

      if (existingKeywordsAsArray.includes(keywordToRemove)) {
        state.searchRequest.From = 0;
        state.searchRequest.Text = existingKeywordsAsArray.filter((k) => k !== keywordToRemove).join(" ");
      }
    },
    clearRequestFilters(state) {
      state.searchRequest.From = defaultSearchRequest.From;
      state.searchRequest.AggregateFilters = defaultSearchRequest.AggregateFilters;
      state.searchRequest.AttachmentTypes = defaultSearchRequest.AttachmentTypes;
      state.searchRequest.OperativeFilters = defaultSearchRequest.OperativeFilters;
      state.searchRequest.ProductClasses = defaultSearchRequest.ProductClasses;
      state.searchRequest.ProductGroups = defaultSearchRequest.ProductGroups;
      state.searchRequest.Text = defaultSearchRequest.Text;
    },
    clearFacetFilters(state) {
      state.searchRequest.From = defaultSearchRequest.From;
      state.searchRequest.AggregateFilters = defaultSearchRequest.AggregateFilters;
      state.searchRequest.OperativeFilters = state.searchRequest.OperativeFilters.filter(
        (f) => f.Name === "Attenuation" || f.Name === "Ventilation operation point"
      );
      state.searchRequest.ProductGroups = defaultSearchRequest.ProductGroups;
    },
    setFrom(state, action: PayloadAction<number>) {
      state.searchRequest.From = action.payload;
    },
    setLanguageLocale(state, action: PayloadAction<SupportedLocale>) {
      state.searchRequest.LanguageLocale = action.payload;
    },
    setMarketAreas(state, action: PayloadAction<string>) {
      state.searchRequest.From = 0;
      state.searchRequest.MarketAreas = [action.payload];
    },
    setProductSetId(state, action: PayloadAction<string | null>) {
      state.searchRequest.From = 0;
      state.searchRequest.ProductSetId = action.payload;
    },
    setSortByOption(state, action: PayloadAction<SortOption>) {
      state.searchRequest.From = 0;
      state.searchRequest.SortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setDataset, (state, action: PayloadAction<Dataset>) => {
      state.searchRequest.Dataset = datasetToDatasetEntriesFormatted(action.payload);
    });
    builder.addCase(addToOrModifyDataset, (state, action: PayloadAction<DatasetEntry>) => {
      const entryFormatted = toDatasetEntryFormatted(action.payload);
      if (!state.searchRequest.Dataset) {
        state.searchRequest.Dataset = [entryFormatted];
        return;
      }

      const entryIndex = state.searchRequest.Dataset.findIndex((x) => x.ProductQpdId === entryFormatted.ProductQpdId);
      if (entryIndex > -1) {
        state.searchRequest.Dataset[entryIndex] = action.payload;
        return;
      }
      state.searchRequest.Dataset.push(action.payload);
    });

    builder.addMatcher(searchApi.endpoints.getManufacturer.matchFulfilled, (state, { payload: manufacturer }) => {
      state.searchRequest.Manufacturers = [manufacturer.Id];
    });
  },
});

export const searchRequestValidSelector = createSelector(
  (state: RootState) => state,
  (state) =>
    state.searchRequest.base64ParamsApplied &&
    isEqual(state.searchRequest.searchRequest.Manufacturers, [state.manufacturer.Id])
);

export const attenuationFilterSelector = createSelector(
  (state: RootState) => state.searchRequest.searchRequest.OperativeFilters,
  (operativeFilters) => operativeFilters.find((of) => of.Name === "Attenuation")
);

export const ventilationOperationPointFilterSelector = createSelector(
  (state: RootState) => state.searchRequest.searchRequest.OperativeFilters,
  (operativeFilters) => operativeFilters.find((f) => f.Name === "Ventilation operation point")
);

export const {
  applyBase64ParamsToSearchRequest,
  addAggregateFilter,
  removeAggregateFilter,
  addAttachmentFilter,
  removeAttachmentFilter,
  addOperativeFilter,
  removeOperativeFilter,
  addProductClass,
  removeProductClass,
  addProductGroups,
  removeProductGroups,
  addTextFilter,
  removeTextFilter,
  clearRequestFilters,
  setFrom,
  setLanguageLocale: setSearchRequestLanguageLocale,
  setMarketAreas: setSearchRequestMarketAreas,
  setSortByOption,
  setProductSetId,
  clearFacetFilters,
} = searchRequestSlice.actions;

export default searchRequestSlice.reducer;
