import { createAggregateFilterChip, ProductUpdateNotification } from "@magicad-cloud/component-library";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useCheckIfProductUpdatesAreAvailableQuery } from "../../api/searchApi";
import { datasetToDatasetEntriesFormatted } from "../../models/dataset";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { addAggregateFilter, clearRequestFilters } from "../../slices/searchRequestSlice";
import { addChip, clearAllChips } from "../../slices/chipsSlice";
import { useEffect, useState } from "react";
import { selectUpdateableChoice } from "../../slices/connectParamsSlice";

export const Notifications = () => {
  const connectUpdateDismissedSessionStorageKey = "connect_update_dismissed";

  const dispatch = useAppDispatch();

  const [productUpdateNotificationDismissed, setProductUpdateNotificationDismissed] = useState(false);
  useEffect(() => {
    const storedValue = sessionStorage.getItem(connectUpdateDismissedSessionStorageKey) === "true";
    setProductUpdateNotificationDismissed(storedValue);
  }, []);

  const onDismiss = () => {
    setProductUpdateNotificationDismissed(true);
    sessionStorage.setItem(connectUpdateDismissedSessionStorageKey, "true");
  };
  const onBrowse = () => {
    const filter = {
      Name: "NewProductVersionAvailable",
      Value: "NewProductVersionAvailable",
    };

    dispatch(clearRequestFilters());
    dispatch(addAggregateFilter(filter));
    dispatch(clearAllChips());
    dispatch(addChip(createAggregateFilterChip(filter)));
    setProductUpdateNotificationDismissed(true);
  };
  const searchRequest = useAppSelector((store) => store.searchRequest.searchRequest);
  const { datasetReady, dataset } = useAppSelector((store) => store.dataset);
  const updateableChoice = useAppSelector(selectUpdateableChoice);

  const datasetFormatted = datasetToDatasetEntriesFormatted(dataset);

  const { data: updatesAvailable } = useCheckIfProductUpdatesAreAvailableQuery(
    !productUpdateNotificationDismissed && datasetReady && datasetFormatted && updateableChoice === 1
      ? searchRequest
      : skipToken
  );

  return (
    <>
      {!productUpdateNotificationDismissed && updatesAvailable ? (
        <ProductUpdateNotification onBrowse={onBrowse} onDismiss={onDismiss} />
      ) : null}
    </>
  );
};
